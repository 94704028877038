.popup-carrito__messageov {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	opacity:0;
	visibility:hidden;
	z-index:1900
}
.popup-carrito__messageov.active {
	opacity:1;
	visibility:visible
}
.popup-carrito__message {
	position:fixed;
	bottom:40px;
	right:30px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	padding:25px 50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-radius:20px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	z-index:2000;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:0;
	visibility:hidden
}
.popup-carrito__message.active {
	opacity:1;
	visibility:visible
}
.popup-carrito__messageText {
	padding-right:20px;
	border-right:2px solid #fff
}
.popup-carrito__messageText p {
	font-size:16px;
	letter-spacing:1.2px;
	color:#fff;
	margin:0;
	text-transform:uppercase
}
.popup-carrito__messageBtn {
	padding-left:35px;
	font-size:16px;
	letter-spacing:1.2px;
	color:#fff;
	font-family:"TTNorms-Bold", sans-serif;
	margin:0;
	text-transform:uppercase
}
@media (max-width:1024px) {
	.popup-carrito__messageText {
		padding-right:10px
	}
	.popup-carrito__messageText p {
		font-size:14px
	}
	.popup-carrito__messageBtn {
		padding-left:10px;
		font-size:14px
	}
	.popup-carrito__message {
		padding:20px
	}
}
@media (max-width:1024px) {
	.popup-carrito__messageText {
		padding-right:10px
	}
	.popup-carrito__messageText p {
		font-size:12px
	}
	.popup-carrito__messageBtn {
		padding-left:10px;
		font-size:12px
	}
}
@media (max-width:480px) {
	.popup-carrito__message {
		padding:20px 10px;
		width:95%;
		right:0;
		left:0;
		margin:auto
	}
	.popup-carrito__messageText {
		padding-right:10px
	}
	.popup-carrito__messageText p {
		font-size:9px
	}
	.popup-carrito__messageBtn {
		padding-left:10px;
		font-size:9px
	}
}
.popUp-gDownload {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	border-radius:10px;
	padding:30px 20px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	position:fixed;
	bottom:30px;
	right:30px;
	z-index:1500;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.popUp-gDownload.active {
	opacity:1;
	visibility:visible
}
.popUp-gDownload > p {
	color:#fff;
	font-size:15px;
	letter-spacing:1.13px;
	margin:0;
	text-transform:uppercase
}
@media (max-width:1024px) {
	.popUp-gDownload {
		padding:15px
	}
	.popUp-gDownload > p {
		font-size:13px
	}
}
