.popUp-g.popUp-g__active .popUp-g__popUp {
	opacity:1;
	visibility:visible;
	pointer-events:auto
}
.popUp-g.popUp-g__active .popUp-g__popUpOverlay {
	opacity:.5;
	visibility:visible;
	pointer-events:auto
}
.popUp-g ::-webkit-scrollbar {
	width:10px
}
.popUp-g ::-webkit-scrollbar-track {
	-webkit-box-shadow:inset 0 0 5px grey;
	box-shadow:inset 0 0 5px grey;
	border-radius:10px;
	background:#DDDDDD
}
.popUp-g ::-webkit-scrollbar-thumb {
	background:rgba(119, 119, 119, 0.5);
	border-radius:10px
}
.popUp-g__popUp {
	position:fixed;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%, -50%);
	-ms-transform:translate(-50%, -50%);
	transform:translate(-50%, -50%);
	width:95%;
	background:white;
	pointer-events:none;
	z-index:1200;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	opacity:0;
	visibility:hidden
}
.popUp-g__popUp form {
	margin-bottom:0 !important
}
.popUp-g__popUpOverlay {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:black;
	pointer-events:none;
	-webkit-transition:all .2s linear;
	-o-transition:all .2s linear;
	transition:all .2s linear;
	z-index:1000;
	opacity:0;
	visibility:hidden
}
.popUp-g__close {
	position:absolute;
	top:15px;
	right:15px;
	text-align:center;
	color:red;
	cursor:pointer;
	font-size:30px;
	z-index:20
}
.popUp-g__popUpScroll {
	overflow:auto;
	height:100%;
	padding-right:20px
}
body.popUp-g__bodyActive {
	overflow:hidden
}
.popUp-gCatalogo .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
	color:#fff !important;
	border:1px solid #fff
}
.popUp-gCatalogo .wpcf7 .wpcf7-not-valid-tip {
	color:#fff !important
}
.popUp-gCatalogo .button-g .ajax-loader {
	color:#fff !important
}
.popUp-gCatalogo .popUp-g__popUp {
	max-width:960px;
	width:90%;
	height:560px;
	border-radius:10px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:40px 20px 40px 40px;
	text-align:center
}
.popUp-gCatalogo .popUp-g__popUpScroll {
	padding-right:0;
	overflow:hidden
}
.popUp-gCatalogo .popUp-g__popUpTitle {
	height:80px;
	max-width:400px;
	width:100%;
	margin:auto;
	text-align:center
}
.popUp-gCatalogo .popUp-g__popUpTitle > h3 {
	font-size:30px;
	line-height:1.3;
	margin:0
}
.popUp-gCatalogo .popUp-g__popUpContent {
	height:calc(100% - 80px);
	overflow:auto;
	padding-right:20px;
	padding-top:15px
}
.popUp-gCatalogo .form-block__button {
	text-align:center
}
.popUp-gCatalogo .input-g__checkboxWP label > span {
	color:#fff
}
@media (max-width:1024px) {
	.popUp-gCatalogo .popUp-g__popUpTitle > h3 {
		font-size:20px
	}
}
@media (max-width:1024px) {
	.popUp-gCatalogo .popUp-g__popUpTitle > h3 {
		font-size:16px
	}
	.popUp-gCatalogo .popUp-g__popUpTitle {
		height:50px
	}
	.popUp-gCatalogo .popUp-g__popUpContent {
		height:calc(100% - 50px)
	}
}
.popUp-gCatalogo .check-confirmation a {
    color:#fff;
    text-decoration:underline;
}
.popUp-gcontacto .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
	color:#fff !important;
	border:1px solid #fff
}
.popUp-gcontacto .wpcf7 .wpcf7-not-valid-tip {
	color:#fff !important
}
.popUp-gcontacto .button-g .ajax-loader {
	color:#fff !important
}
.popUp-gcontacto .popUp-g__popUp {
	max-width:960px;
	width:90%;
	height:815px;
	border-radius:10px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:40px 20px 40px 40px;
	text-align:center
}
.popUp-gcontacto .popUp-g__popUpScroll {
	padding-right:0;
	overflow:hidden
}
.popUp-gcontacto .popUp-g__popUpTitle {
	max-width:750px;
	width:100%;
	height:80px;
	margin:auto;
	text-align:center
}
.popUp-gcontacto .popUp-g__popUpTitle > h3 {
	font-size:30px;
	line-height:1.3
}
.popUp-gcontacto .popUp-g__popUpContent {
	height:calc(100% - 80px);
	overflow:auto;
	padding-right:20px;
	padding-top:15px
}
.popUp-gcontacto .form-block__button {
	text-align:center
}
.popUp-gcontacto .input-g__title {
	color:#fff !important
}
.popUp-gcontacto .button-g--gradient .button-g__text {
	background:#fff;
	color:#E3051B
}
.popUp-gcontacto .input-g__checkboxWP label > span {
	color:#fff
}
.popUp-gcontacto .input-g__checkboxWP label > span > a {
	color:#fff;
	text-decoration:underline
}
@media (max-width:1024px) {
	.popUp-gcontacto .popUp-g__popUp {
		height:650px
	}
	.popUp-gcontacto .popUp-g__popUpTitle > h3 {
		font-size:20px
	}
}
@media (max-width:1024px) {
	.popUp-gcontacto .popUp-g__popUpTitle > h3 {
		font-size:16px
	}
}
.popUp-gcontacto .check-confirmation a {
    color:#fff;
    text-decoration:underline;
}
.popUp-gGuia .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
	border:1px solid #fff !important;
	color:#fff !important
}
.popUp-gGuia .wpcf7 .wpcf7-not-valid-tip {
	color:#fff !important
}
.popUp-gGuia .button-g .ajax-loader {
	color:#fff !important
}
.popUp-gGuia .popUp-g__popUp {
	max-width:960px;
	width:90%;
	height:565px;
	border-radius:10px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:40px 20px 40px 40px;
	text-align:center
}
.popUp-gGuia .popUp-g__popUpScroll {
	padding-right:0;
	overflow:hidden
}
.popUp-gGuia .popUp-g__popUpTitle {
	max-width:500px;
	width:100%;
	height:80px;
	margin:auto;
	text-align:center
}
.popUp-gGuia .popUp-g__popUpTitle > h3 {
	font-size:30px;
	line-height:1.4
}
.popUp-gGuia .popUp-g__popUpContent {
	height:calc(100% - 80px);
	overflow:auto;
	padding-right:20px;
	padding-top:15px
}
.popUp-gGuia .form-block__button {
	text-align:center
}
.popUp-gGuia .input-g__checkboxWP label > span {
	color:#fff
}
@media (max-width:1024px) {
	.popUp-gGuia .popUp-g__popUpTitle > h3 {
		font-size:20px
	}
}
@media (max-width:1024px) {
	.popUp-gGuia .popUp-g__popUpTitle > h3 {
		font-size:16px
	}
}
.popUp-gGuia .check-confirmation a {
    color:#fff;
    text-decoration:underline;
}
.popUp-gMessageFault .popUp-g__popUpOverlay {
	z-index:1400
}
.popUp-gMessageFault .popUp-g__popUp {
	max-width:695px;
	width:90%;
	height:320px;
	border-radius:10px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:40px 40px 40px 60px;
	text-align:center;
	z-index:1500
}
.popUp-gMessageFault .popUp-g__popUpTitle > span {
	font-size:65px;
	color:#fff
}
.popUp-gMessageFault .popUp-g__popUpTitle > h3 {
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#fff;
	margin:0;
	margin-top:22px;
	line-height:1.3
}
.popUp-gMessageFault .popUp-g__popUpContent {
	max-width:450px;
	width:100%;
	margin:auto;
	margin-top:30px;
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#fff;
	line-height:1.1
}
.popUp-gOlvidaste .popUp-g__popUp {
	max-width:695px;
	width:90%;
	height:400px;
	border-radius:10px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:40px 40px 40px 60px;
	text-align:center;
	z-index:1500
}
.popUp-gOlvidaste .popUp-g__popUpTitle > span {
	font-size:65px;
	color:#fff;
	letter-spacing:2px
}
.popUp-gOlvidaste .popUp-g__popUpTitle > h3 {
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#fff;
	margin:0;
	margin-top:22px;
	line-height:1.3
}
.popUp-gOlvidaste .popUp-g__popUpContent {
	max-width:450px;
	width:100%;
	margin:auto;
	margin-top:30px;
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#fff;
	line-height:1.1
}
.popUp-gOlvidaste .form-block {
	text-align:center
}
.popUp-gOlvidaste .form-block__text {
	font-size:17px;
	letter-spacing:1px;
	color:#fff;
	font-family:"TTNorms-Regular", sans-serif;
	line-height:1.3
}
.popUp-gOlvidaste .form-block__button {
	text-align:center
}
.popUp-gOlvidaste .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
	display:none !important
}
.popUp-gOlvidaste .wpcf7 .wpcf7-not-valid-tip {
	color:#fff !important
}
.popUp-gOlvidaste .button-g .ajax-loader {
	color:#fff
}
.popUp-gVideo .popUp-g__popUp {
	padding:0 !important;
	background:transparent;
	width:90%;
	max-width:1080px;
	height:auto
}
.popUp-gVideo .popUp-g__close {
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:100%;
	text-align:center;
	top:-25px;
	right:-25px;
	background:#E95B0C;
	color:#fff;
	font-size:30px
}
.popUp-gVideo .popUp-g__popUpScroll {
	padding-right:0
}
.popUp-gVideoUrl {
	width:100%;
	height:500px
}
@media (max-width:1024px) {
	.popUp-gVideo .popUp-g__popUp {
		width:95% !important;
		height:auto !important
	}
	.popUp-gVideo .popUp-g__close {
		width:40px;
		height:40px;
		line-height:40px;
		top:0 !important;
		right:0 !important;
		font-size:20px
	}
	.popUp-gVideoUrl {
		height:250px !important
	}
}
@media (max-width:1024px) {
	.popUp-g__popUp {
		padding:50px 10px 30px 20px !important;
		height:90% !important
	}
	.popUp-g__popUpContent {
		padding-left:0 !important
	}
	.popUp-g__close {
		right:15px !important;
		top:15px !important;
		font-size:25px
	}
	.popUp-g__popUpScroll {
		padding-right:10px
	}
	.popUp-gMessageFault .popUp-g__popUp {
		height:auto !important
	}
	.popUp-gMessageFault .popUp-g__popUpTitle > span {
		font-size:50px
	}
	.popUp-gMessageFault .popUp-g__popUpTitle > h3 {
		font-size:17px
	}
	.popUp-gMessageFault .popUp-g__popUpContent {
		font-size:15px
	}
}
