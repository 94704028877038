html {
    scroll-padding-top:120px;
}
.main-header {
	position:relative;
	overflow:hidden;
	height:120px;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.main-header__all {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:120px;
	z-index:800;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	background:#fff;
	-webkit-box-shadow:0 1px 6px rgba(0, 0, 0, 0.2);
	box-shadow:0 1px 6px rgba(0, 0, 0, 0.2)
}
.main-header__container {
	height:100%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.main-header__logo {
	width:420px;
	height:100%;
	margin:0;
	display:block;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center
}
.main-header__logo img {
	display:block;
	max-width:100%;
	height:auto
}
.main-header__inside {
	height:100%;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.main-header__inside {
	height:100%;
	width:calc(100% - 420px);
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.main-header__nav {
	height:100%;
	width:calc(100% - 60px);
	overflow:hidden
}
.main-header__list {
	height:100%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	margin:0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.main-header__list > li {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	height:100%;
	margin-right:5%;
	position:relative;
	overflow:hidden
}
.main-header__list > li:first-child {
	margin-left:4%
}
.main-header__list > li:last-child {
	margin-right:0;
	position:relative
}
.main-header__list > li > a {
	display:inline-block;
	vertical-align:middle;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	color:#3C3C3B;
	font-size:15px;
	text-transform:uppercase;
	position:relative;
	z-index:2;
	padding:0 0 4px 0
}
.main-header__list > li > a:before {
	content:"";
	position:absolute;
	bottom:0;
	left:0;
	width:0;
	height:4px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.main-header__list > li > a:hover:before {
	width:100%
}
.main-header__list > li.current_page_item > a:before {
	width:100%
}
/*.main-header__userCarrito {
	background:#ccc;
	width:60px;
	height:100%
}
.main-header__userCarrito > ul {
	height:100%
}
.main-header__userCarrito > ul > li {
	height:50%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center
}
.main-header__user {
	background:url("../img/icons/icon-head-02.svg");
	width:24px;
	height:28px;
	display:block
}
.main-header__car {
	background:url("../img/icons/icon-head-03.svg");
	width:30px;
	height:28px;
	display:block;
	position:relative
}
.main-header__car > i {
	font-size:9px;
	color:#fff;
	font-family:"TTNorms-Bold", sans-serif;
	padding:2px 4px;
	font-style:normal;
	border-radius:20px;
	background:#E3051B;
	position:absolute;
	right:-4px;
	top:-6px
}*/
.main-header__language {
	display:inline-block;
	position:absolute;
	bottom:15px;
	right:0;
	pointer-events:none
}
.main-header__language ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	pointer-events:auto
}
.main-header__language ul > li {
	padding:0 5px;
	position:relative
}
.main-header__language ul > li.current-lang > a {
	color:#7A7A7A;
    position:relative;
}
.main-header__language ul > li.current-lang > a:before, .main-sidebar__language ul > li.current-lang > a:before {
    content:'';
    border-bottom:2px solid #7A7A7A;
    width:100%;
    position:absolute;
    bottom:0px;
}
.main-sidebar__language ul > li.current-lang a {
    position:relative;
}
.main-sidebar__language ul > li.current-lang > a:before {
    border-color:#B3B3B3;
}
.main-header__language ul > li:after {
	content:"/";
	font-size:14px;
	color:#B3B3B3;
	position:absolute;
	right:-3px;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%)
}
.main-header__language ul > li:last-child:after {
	display:none
}
.main-header__language ul > li > a {
	font-size:.75em;
	color:#B3B3B3;
	font-family:"TTNorms-Bold", sans-serif;
	text-transform:uppercase
}
.header-main-burguer.burguer-g {
	position:fixed;
	top:25px;
	right:20px;
	display:none
}
.header-main-burguer.main-burguer--open {
	z-index:1000
}
.main-sidebar {
	position:fixed;
	top:-100%;
	right:0;
	width:100%;
	height:100%;
	overflow-y:auto;
	background:#F2F2F2;
	z-index:1100;
	left:0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.main-sidebar.active {
	top:0
}
.main-sidebar__overlay {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:#000;
	z-index:850;
	pointer-events:none;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.main-sidebar__overlay.active {
	opacity:.6;
	visibility:visible;
	pointer-events:auto
}
.main-sidebar__head {
	height:70px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	padding:0 15px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.main-sidebar__language {
	margin-top:20px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end;
	padding-right:40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.main-sidebar__language .widget {
	margin:0
}
.main-sidebar__language ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.main-sidebar__language ul > li {
	padding:0 5px
}
.main-sidebar__language ul > li:after {
	content:"/";
	font-size:14px;
	color:#B3B3B3
}
.main-sidebar__language ul > li:last-child:after {
	display:none
}
.main-sidebar__language ul > li > a {
	font-size:14px;
	color:#B3B3B3;
	font-family:"TTNorms-Bold", sans-serif;
	text-transform:uppercase
}
.main-sidebar__logo {
	display:block
}
.main-sidebar__logo img {
	max-width:100%;
	height:auto;
	display:block
}
/*.main-sidebar__userCarrito {
	background:#ccc;
	width:130px;
	height:65px;
	position:absolute;
	right:0;
	margin-top:-65px
}
.main-sidebar__userCarrito > ul {
	height:100%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.main-sidebar__userCarrito > ul > li {
	width:50%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center
}
.main-sidebar__user {
	background:url("../img/icons/icon-head-02.svg");
	width:24px;
	height:28px;
	display:block
}
.main-sidebar__car {
	background:url("../img/icons/icon-head-03.svg");
	width:30px;
	height:28px;
	display:block
}*/
.main-sidebar__nav {
	margin-top:15px
}
.main-sidebar__list > li.current_page_item > a:after {
	width:100%
}
.main-sidebar__list > li {
	padding:15px 0 15px 0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	min-height:65px;
	text-align:center
}
.main-sidebar__list > li > a {
	font-size:14px;
	color:#3C3C3B;
	text-transform:uppercase;
	text-align:center;
	position:relative;
	display:inline-block;
	padding-bottom:4px
}
.main-sidebar__list > li > a:after {
	content:"";
	position:absolute;
	left:0;
	bottom:0;
	width:0;
	height:2px;
	background:#E3051B
}
@media(min-width:768px) and (max-width:1024px){
	.main-sidebar__list>li>a {
		font-size: 20px;
	}
}
@media (max-width:1600px) {
	.main-header__list > li {
		margin-right:3%
	}
	.main-header__list > li:first-child {
		margin-left:3%
	}
	.main-header__logo {
		width:20%
	}
	.main-header__inside {
		width:80%
	}
	.main-header__list > li:last-child {
		width:auto
	}
	.main-header__list > li:last-child {
		padding:0 25px
	}
}
@media (max-width:1300px) {
	.header-main-burguer.burguer-g {
		display:block
	}
	.header-main-burguer.burguer-g {
		display:block
	}
    html {
        scroll-padding-top:70px;
    }
	.main-header {
		height:70px
	}
	.main-header__all {
		height:70px
	}
	.main-header__nav {
		display:none
	}
	/*.main-header__userCarrito {
		display:none
	}*/
	.main-header__logo {
		width:25%
	}
	.main-header__inside {
		width:75%
	}
	.main-sidebar__logo {
		width:25%;
		position:relative;
		left:-20px
	}
	.burguer-g--openClose.burguer-g > span {
		background:#E3051B
	}
	.main-sidebar .burguer-g--openClose.burguer-g > span {
		background:#fff
	}
}
@media (max-width:1024px) {
	.main-header__all {
		background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
		background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
		background:linear-gradient(to right, #e2051b 0%, #e9590c 100%)
	}
	.main-header__logo {
		background:transparent
	}
	.burguer-g--openClose.burguer-g > span {
		background:#fff
	}
	.main-sidebar__head {
		padding-left:0
	}
	.main-sidebar__logo {
		max-width:180px;
		width:100%;
		left:0
    }
}
.burguer-g {
	display:block;
	width:25px;
	height:20px;
	position:relative;
	cursor:pointer
}
.burguer-g > span {
	display:block;
	width:100%;
	height:4px;
	border-radius:5px;
	background:#E3051B;
	-webkit-transition:all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition:all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition:all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)
}
/*.burguer-g--icon {
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center;
	background:red;
	color:white
}
.burguer-g--line span:nth-child(1) {
	position:absolute;
	top:0;
	left:0
}
.burguer-g--line span:nth-child(2) {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	right:-8px
}
.burguer-g--line span:nth-child(3) {
	position:absolute;
	bottom:0
}
.burguer-g--line:hover span:nth-child(2) {
	right:0
}
.burguer-g--line.active span:nth-child(2) {
	right:0
}
.burguer-g--inline span:nth-child(1) {
	position:absolute;
	top:0;
	left:0;
	width:60%
}
.burguer-g--inline span:nth-child(2) {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	width:80%
}
.burguer-g--inline span:nth-child(3) {
	position:absolute;
	bottom:0
}
.burguer-g--inline:hover span:nth-child(2) {
	width:100%
}
.burguer-g--inline:hover span:nth-child(1) {
	width:100%
}
.burguer-g--inline.active span:nth-child(2) {
	width:100%
}
.burguer-g--inline.active span:nth-child(1) {
	width:100%
}*/
.burguer-g--openClose span:nth-child(1) {
	position:absolute;
	top:0;
	left:0
}
.burguer-g--openClose span:nth-child(2) {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%)
}
.burguer-g--openClose span:nth-child(3) {
	position:absolute;
	bottom:0
}
.burguer-g--openClose.active span:nth-child(2) {
	opacity:0
}
.burguer-g--openClose.active span:nth-child(1) {
	-webkit-transform:rotate(45deg);
	-ms-transform:rotate(45deg);
	transform:rotate(45deg);
	top:50%
}
.burguer-g--openClose.active span:nth-child(3) {
	-webkit-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	transform:rotate(-45deg);
	bottom:45%
}
/*.burguer-g--center span:nth-child(1) {
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:50%
}
.burguer-g--center span:nth-child(2) {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%)
}
.burguer-g--center span:nth-child(3) {
	position:absolute;
	bottom:0;
	width:50%;
	left:0;
	right:0
}
.burguer-g--center:hover span:nth-child(1) {
	width:100%
}
.burguer-g--center:hover span:nth-child(3) {
	width:100%
}
.burguer-g--center.active span:nth-child(1) {
	width:100%
}
.burguer-g--center.active span:nth-child(3) {
	width:100%
}*/
@media (max-width:1024px) {
	.burguer-g {
		width:20px;
		height:20px
	}
}
