/*.colorSilver {
	color:gray
}
.colorSilver2 {
	color:#E5E5E5
}
.colorSilver3 {
	color:#E4E4E4
}
.colorSilver4 {
	color:#F1F1F1
}
.colorSilver6 {
	color:#848483
}*/
.colorOrange {
	color:#E3051B
}
.colorWhite {
	color:#fff
}
.colorBlack {
	color:#000
}
.textUnderline {
	text-decoration:underline !important
}
html {
	font-family:"TTNorms-Regular", sans-serif
}
input:focus, button:focus, textarea:focus, select:focus {
	border:none
}
*:focus {
	outline:0 !important
}
button, input {
	line-height:normal
}
body, span, iframe, h1, h2, h3, h4, h5, h6, a, img, form, label, table, tbody, tfoot, thead, tr, th, td, article, figure, footer, header, section {
	margin:auto;
	padding:0;
	border:0;
	font-weight:inherit;
	font-style:inherit;
	font-size:100%;
	font-family:inherit;
	vertical-align:baseline
}
html {
	height:100%
}
body {
	height:auto;
}
h1 {
	font-size:50px;
	margin:0 0 10px;
	line-height:1
}
h2 {
	margin:0 0 10px;
	font-size:35px;
	line-height:1
}
h3 {
	margin:0 0 10px;
	font-size:25px;
	line-height:1
}
h4 {
	padding:0;
	margin:0 0 10px;
	font-size:20px;
	line-height:1
}
h5 {
	margin:0 0 10px;
	font-size:18px;
	line-height:1
}
a img {
	border:none
}
p {
	margin:0 0 15px
}
label {
	cursor:pointer
}
textarea {
	resize:none
}
th, td {
	font-weight:normal
}
table, th, td {
	vertical-align:middle
}
br {
	line-height:1
}
.list--none ul {
	margin:0 !important;
	padding:0 !important
}
.list--none li {
	list-style:none !important
}
ul.list--none {
	padding:0 !important
}
ul.list--none li {
	list-style:none !important
}
.g--uppercase {
	text-transform:uppercase
}
.g--uppercase * {
	text-transform:uppercase
}
.wrapper-container {
	width:100%;
	margin:0 auto;
	position:relative;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.wrapper-container-full {
	max-width:100%;
	position:relative;
	padding:0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
@media (min-width:1024px) and (max-width:2600px) {
	.wrapper-container {
		padding:0 40px;
		max-width:1680px
	}
}
@media (min-width:768px) and (max-width:1024px) {
	.wrapper-container {
		padding:0 40px;
		max-width:1280px
	}
}
@media (min-width:480px) and (max-width:1024px) {
	.wrapper-container {
		padding:0 40px;
		max-width:1280px
	}
}
@media (min-width:320px) and (max-width:479px) {
	.wrapper-container {
		padding:0 25px;
		max-width:1250px
	}
}
@media (min-width:1024px) and (max-width:2600px) {
	.wrapper-padding {
		padding:65px 0 !important
	}
	/*.wrapper-paddingTop {
		padding:65px 0 0 0 !important
	}*/
	.wrapper-paddingBottom {
		padding:0 0 65px 0 !important
	}
}
@media (min-width:768px) and (max-width:1024px) {
	.wrapper-padding {
		padding:55px 0 !important
	}
	/*.wrapper-paddingTop {
		padding:55px 0 0 0 !important
	}*/
	.wrapper-paddingBottom {
		padding:0 0 55px 0 !important
	}
}
@media (min-width:320px) and (max-width:1024px) {
	.wrapper-padding {
		padding:40px 0 !important
	}
	/*.wrapper-paddingTop {
		padding:40px 0 0 0 !important
	}*/
	.wrapper-paddingBottom {
		padding:0 0 40px 0 !important
	}
}
.wpcf7-form-control-wrap {
	display:block
}
.wpcf7 .wpcf7-response-output {
	margin:15px 0;
	padding:10px;
	font-size:14px;
	border-radius:3px;
	color:#E3051B
}
.left {
	text-align:left
}
.right {
	text-align:right
}
.center {
	text-align:center
}
/*.alignleft {
	display:inline;
	float:left;
	margin-right:1.5em
}
.alignright {
	display:inline;
	float:right;
	margin-left:1.5em
}
.aligncenter {
	clear:both;
	display:block;
	margin-left:auto;
	margin-right:auto
}
@media (max-width:1024px) {
	.alignleft {
		display:block;
		margin:15px
	}
	.alignright {
		display:block;
		margin:15px
	}
}*/
.main-content {
	overflow:hidden
}
.main-footer {
	background:#E6E6E6;
	overflow:hidden
}
.main-footer__top {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	padding:40px 0
}
.main-footer__bottom {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	padding:40px 0;
	border-top:1px solid #ccc
}
.main-footer__newsletter {
	width:50%;
	margin-right:auto;
	margin-left:0
}
.main-footer__newsletterTitle > h3 {
	font-size:15px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	margin-bottom:5px
}
.main-footer__newsletterTitle > p {
	font-size:15px;
	color:#3C3C3B
}
.main-footer__socialnetwork {
	width:33%
}
.main-footer__socialnetworkTitle > h3 {
	font-size:15px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	margin-bottom:5px
}
.main-footer__socialnetworkTitle > p {
	font-size:15px;
	color:#3C3C3B
}
.main-footer__social > ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.main-footer__social > ul > li {
	margin-right:25px
}
.main-footer__social > ul > li:last-child {
	margin-right:0
}
.main-footer__social > ul > li > a {
	display:block;
	text-align:center;
	width:50px;
	height:50px;
	font-size:20px;
	line-height:50px;
	border-radius:5px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
@media (min-width:1025px) {
	.main-footer__social > ul > li > a:hover {
		-webkit-transform:scale(1.06);
		-ms-transform:scale(1.06);
		transform:scale(1.06);
		-webkit-box-shadow:0px 0px 15px rgba(0, 0, 0, 0.3);
		box-shadow:0px 0px 15px rgba(0, 0, 0, 0.3)
    }
}
.main-footer__bottom {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.main-footer__info {
	width:27%
}
.main-footer__img {
	max-width:100%;
	height:auto;
	display:block;
	max-width:248px;
	width:100%;
	margin:0;
	margin-bottom:25px
}
.main-footer__img2 {
	max-width:100%;
	height:auto;
	display:block;
	margin:0;
	max-width:248px;
	width:100%;
	margin-bottom:20px
}
.main-footer__address {
	margin-bottom:20px;
	font-size:15px;
	color:#3C3C3B
}
.main-footer__address p {
	margin:0;
	line-height:2
}
.main-footer__email > h3, .main-footer__telephone > h3 {
	font-size:15px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	margin-bottom:15px
}
.main-footer__email > a, .main-footer__telephone > a {
	font-size:15px;
	color:#3C3C3B
}
.main-footer__email {
	margin-bottom:20px
}
.main-footer__columnsAll {
	overflow:hidden
}
.main-footer__columnnavItem {
	margin-bottom:45px
}
.main-footer__columnnavItem:last-child {
	margin-bottom:0
}
.main-footer__columnnav4 .main-footer__columnnavItem {
	margin-bottom:27px
}
.main-footer__columnnav4 .main-footer__columnnavItem:last-child {
	margin-bottom:0
}
.main-footer__list > li {
	margin-bottom:10px
}
.main-footer__list > li:last-child {
	margin-bottom:0
}
.main-footer__list > li:first-child > a {
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	text-transform:uppercase;
	line-height:1.3
}
.main-footer__list > li > a {
	font-size:15px;
	color:#3C3C3B
}
.main-footer__list > li > a:hover {
	font-weight:bold
}
.main-footer__columnsResp {
	display:none
}
.main-footer__columnsDesktop .main-footer__columnsAll {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.main-footer__columnsDesktop .main-footer__columnsAllItem {
	display:inline-block;
	padding-right:70px
}
.main-footer__columnsDesktop .main-footer__columnsAllItem:last-child {
	padding-right:0
}
.main-footer__newsletterForm form {
	margin:0 !important
}
.main-footer__newsletterForm .input-g__line .input-g {
	margin:0 !important
}
.main-footer__newsletterForm .input-g__input {
	height:50px !important;
	line-height:50px !important;
	background-color:#fff !important;
	padding-right:170px !important
}
.main-footer__newsletterForm .form-block__container {
	position:relative
}
.main-footer__newsletterForm .form-block__button {
	position:absolute;
	top:0;
	right:0;
	margin:0
}
.main-footer__newsletterForm .form-block__button .button-g {
	border-radius:0 6px 6px 0 !important
}
.main-footer__newsletterForm .form-block__button .button-g .button-g__text {
	border-radius:0 6px 6px 0 !important
}
.main-footer__copyright {
	padding:14px 0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-top:1px solid #ccc
}
.main-footer__copyright {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.main-footer__list2 > li {
	padding:0 10px;
	position:relative
}
.main-footer__list2 > li:after {
	content:"";
	width:1px;
	height:10px;
	background:#3C3C3B;
	display:inline-block;
	vertical-align:middle;
	position:absolute;
	top:50%;
	right:0;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%)
}
.main-footer__list2 > li > a {
	font-size:15px;
	color:#3C3C3B
}
.main-footer__design > li:last-child:after {
	display:none
}
.main-footer__listTerminos > li:first-child {
	padding-left:0
}
.main-footer__copyrightLinks {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.main-footer__listTerminos, .main-footer__design {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.main-footer__copyrightText {
	font-size:15px;
	color:#3C3C3B
}
.main-footer__copyrightText p {
	margin:0
}
.main-footer__columns .swiper-pagination {
	display:none
}
@media (max-width:1200px) {
	.main-footer__socialnetwork {
		width:40%
    }
    /**/
	.main-footer__columnsDesktop .main-footer__columnsAllItem {
		padding-right:40px
	}
	.main-footer__newsletterForm .input-g__input {
		height:45px !important;
		line-height:45px !important
    }
}
@media (max-width:1024px) {
	.main-footer__columnsDesktop {
		display:none
	}
	.main-footer__columnsResp {
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex
	}
	.main-footer__columns {
		overflow:hidden;
		width:calc(100% - 28%);
		position:relative;
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.main-footer__columnsAll {
		overflow:inherit
	}
}
@media (max-width:1024px) {
	.main-footer__copyright {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center;
		text-align:center
	}
	.main-footer__copyrightText {
		width:100%;
		font-size:14px
	}
	.main-footer__copyrightLinks {
		width:100%;
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center
	}
	.main-footer__list2 > li > a {
		font-size:14px
	}
}

@media (max-width:1024px) {
	.main-footer__newsletterTitle > p {
		display:none
	}
	.main-footer__top {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.main-footer__bottom {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		width:100%
	}
	.main-footer__newsletter {
		width:50%;
		text-align:center;
		margin-bottom:40px
	}
	.main-footer__socialnetwork {
		width:50%;
		text-align:center
	}
	.main-footer__socialnetworkTitle > p {
		display:none
	}
	.main-footer__social > ul {
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center
	}
	.main-footer__newsletterTitle > h3 {
		margin-bottom:35px
	}
	.main-footer__socialnetworkTitle > h3 {
		margin-bottom:35px
	}
	.main-footer__newsletterForm .input-g__input {
		height:35px !important;
		line-height:35px !important
	}
	.main-footer__social > ul > li > a {
		height:40px;
		width:40px;
		line-height:40px;
		font-size:15px
	}
	.main-footer__info {
		width:100%;
		-webkit-box-ordinal-group:3;
		-ms-flex-order:2;
		order:2;
		margin-top:30px;
		padding-top:40px;
		border-top:1px solid #ccc
	}
	.main-footer__address {
		font-size:14px
	}
	.main-footer__email > h3, .main-footer__telephone > h3 {
		font-size:14px
	}
	.main-footer__email > a, .main-footer__telephone > a {
		font-size:14px
	}
	.main-footer__copyrightLinks {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.main-footer__list2 > li:last-child {
		padding-right:0
	}
	.main-footer__list2 > li:last-child:after {
		display:none
	}
	.main-footer__list2 > li > a {
		font-size:13px
	}
	.main-footer__columnsRespAll {
		padding-bottom:55px
	}
	.main-footer__columns {
		width:100%;
		-webkit-box-ordinal-group:2;
		-ms-flex-order:1;
		order:1
	}
	.main-footer__columns .swiper-pagination {
		bottom:0 !important;
		display:block
	}
	.main-footer__columns .swiper-pagination-bullet {
		background:gray
	}
	.main-footer__columns .swiper-pagination-bullet-active {
		background:#000
	}
	.main-footer__columnnavItem {
		text-align:center
	}
	.main-footer__img {
		max-width:204px;
		margin:auto;
		margin-bottom:20px
	}
	.main-footer__img2 {
		max-width:204px;
		margin:auto;
		margin-bottom:30px
	}
}
@media (max-width:727px) {
    .main-footer__newsletter {
        width: 100%;
        text-align: center;
        margin-bottom: 40px
    }

    .main-footer__socialnetwork {
        width: 100%;
        text-align: center;
    }
}
.footer-links {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%)
}
.footer-links__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.footer-links__item {
	width:25%;
	text-align:center;
	height:200px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-ms-flex-direction:column;
	flex-direction:column;
	border-right:1px solid rgba(0, 0, 0, 0.2);
	padding:10px 15px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	position:relative
}
.footer-links__item:hover:before {
	opacity:1;
	visibility:visible
}
.footer-links__item:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, 0.15);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:0;
	visibility:hidden
}
.footer-links__item:last-child {
	border-right:none
}
.footer-links__image {
	max-width:55px;
	width:100%;
	margin:0 auto;
	margin-bottom:25px;
	position:relative;
	z-index:2
}
.footer-links__image img {
	max-width:100%;
	height:auto;
	display:block
}
.footer-links__text {
	position:relative;
	z-index:2
}
.footer-links__text > h3 {
	font-size:21px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#fff;
	margin:0;
	line-height:1.2;
	letter-spacing:1.2px
}
@media (max-width:1024px) {
	.footer-links__item {
		width:50%;
		border-bottom:1px solid rgba(0, 0, 0, 0.2)
	}
	.footer-links__item:nth-child(2n+2) {
		border-right:none
	}
	.footer-links__text > h3 {
		font-size:18px
	}
}
@media (max-width:767px) {
	.footer-links__item {
		width:100%;
		height:110px;
		border-right:none !important;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-ms-flex-direction:row;
		flex-direction:row;
		padding:10px
	}
	.footer-links__item:last-child {
		border-bottom:none !important
	}
	.footer-links__image {
		margin-bottom:0;
		margin-right:10px
	}
	.footer-links__text > h3 {
		font-size:16px;
		letter-spacing:1.2px
	}
	.footer-links__image {
		width:28px;
		height:28px;
		margin:0
	}
	.footer-links__text {
		padding-left:8px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
}
.button-g {
	display:inline-block;
	position:relative;
	border-radius:0;
	background:none;
	border:none;
	padding:0;
	margin:0;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	letter-spacing:normal;
	cursor:pointer;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	border-radius:8px
}
@media (min-width:1025px) {
	.button-g:hover {
		-webkit-transform:scale(1.06);
		-ms-transform:scale(1.06);
		transform:scale(1.06);
		-webkit-box-shadow:0px 0px 20px rgba(0, 0, 0, 0.3);
		box-shadow:0px 0px 20px rgba(0, 0, 0, 0.3)
	}
}
.button-g__text {
	border-radius:0;
	background:none;
	border:none;
	padding:0;
	margin:0;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	position:relative;
	z-index:3;
	display:block;
	height:50px;
	line-height:50px;
	padding:0 !important;
	padding:0 30px !important;
	border-radius:8px !important;
	letter-spacing:1.13px
}
.button-g__icon {
	display:block;
	font-style:normal;
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	right:0
}
.button-g .ajax-loader {
	position:absolute;
	top:25%;
	display:block;
	width:auto !important;
	height:auto !important;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	right:-25px;
	-webkit-animation:icon-submit 1.8s infinite linear;
	animation:icon-submit 1.8s infinite linear;
	background-image:none !important;
	color:#E3051B
}
.button-g .ajax-loader:before {
	content:"\e97a";
	font-family:'icomoon'
}
@-webkit-keyframes icon-submit {
	0% {
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg)
	}
	100% {
		-webkit-transform:rotate(359deg);
		transform:rotate(359deg)
	}
}
@keyframes icon-submit {
	0% {
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg)
	}
	100% {
		-webkit-transform:rotate(359deg);
		transform:rotate(359deg)
	}
}
.button-g--gradient .button-g__text {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	font-size:15px;
	font-family:"TTNorms-Medium", sans-serif;
	color:#fff
}
.button-g--gradient:hover .button-g__text {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	color:#fff
}
.button-g--white .button-g__text {
	background:#fff;
	font-size:15px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B
}
.button-g--white:hover .button-g__text {
	background:#fff;
	color:#E3051B
}
@media (max-width:1024px) {
	.button-g__text {
		height:35px;
		line-height:35px;
		font-size:13px !important;
		padding:0 15px !important;
		letter-spacing:0.98px !important
	}
}
@media (max-width:400px) {
	.button-g__text {
		height:35px;
		line-height:35px;
		font-size:12px !important;
		padding:0 15px !important
	}
}
@media (max-width:365px) {
	.button-g__text {
		font-size:12px !important;
		padding:0 10px !important
	}
}
.input-g {
	text-align:left;
	position:relative
}
.input-g__input {
	width:100%;
	height:40px;
	line-height:40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border:none;
	margin:0 !important;
	padding:0 !important;
	border-radius:5px !important;
	background:none !important;
	-webkit-box-shadow:none !important;
	box-shadow:none !important;
	font-size:18px !important;
	color:#000 !important;
	background-color:#ddd !important;
	padding:0 15px !important
}
.input-g__input .input__g__input::-webkit-input-placeholder {
	font-size:18px !important;
	color:#848483 !important;
}
.input-g__input .input__g__input::-moz-placeholder {
	font-size:18px !important;
	color:#848483 !important;
}
.input-g__input .input__g__input:-ms-input-placeholder {
	font-size:18px !important;
	color:#848483 !important;
}
.input-g__input .input__g__input::-moz-placeholder {
	font-size:18px !important;
	color:#848483 !important;
}
.input-g--textarea .input-g__input {
	height:125px;
	line-height:1.1;
	padding-top:15px !important
}
.input-g--select--default {
	position:relative
}
.input-g--select--default .input-g__input {
	position:relative
}
.input-g--select .input-g__input {
	padding-right:35px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	cursor:pointer
}
.input-g--select .input-g__input::-ms-expand {
	display:none
}
.input-g--select .input-g__ico {
	right:15px;
	top:18px;
	display:block;
	background:url("../img/icons/arrow-bottom.svg");
	width:13px;
	height:8px;
	background-size:100%;
	background-position:0 0;
	background-repeat:no-repeat
}
.input-g--white.input-g .input-g__input {
	border:1px solid white;
	color:white;
	background:white
}
.input-g--white .input-g__title {
	color:white
}
.input-g--white .input-g__ico {
	color:white
}
.input-g--inline {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.input-g--inline .input-g__title {
	margin:0;
	margin-right:15px
}
.input-g--inline .input-g__inside {
	width:100%
}
.input-g__title {
	margin:0;
	margin-bottom:5px;
	display:block;
	font-size:15px;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.input-g__inside {
	position:relative
}
.input-g__ico {
	display:block;
	pointer-events:none;
	background:none;
	border:none;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	padding:0;
	margin:0;
	z-index:2;
	position:absolute;
	top:0;
	right:20px;
	color:black;
	font-size:17px;
	line-height:45px
}
.input-g__icoLeft {
	left:15px
}
.input-g__icoRight {
	right:15px
}
.input-g--iconLeft .input-g__input {
	padding-left:40px
}
.input-g--iconRight .input-g__input {
	padding-right:40px
}
.input-g--iconAll .input-g__input {
	padding-right:40px;
	padding-left:40px
}
.input-g--button .input-g__ico {
	pointer-events:auto
}
.input-g__checkboxWP .wpcf7-list-item {
	margin:0;
	line-height:1.1
}
.input-g__checkboxWP label {
	padding-left:0
}
.input-g__checkboxWP label > input {
	position:absolute;
	left:0;
	top:0;
	opacity:0;
	visibility:hidden
}
.input-g__checkboxWP label > span {
	position:relative;
	padding-left:20px;
	font-size:14px;
	line-height:1.2;
	color:#848483
}
.input-g__checkboxWP label > span:after {
	content:"\ea10";
	font-family:'icomoon';
	position:absolute;
	left:3px;
	top:5px;
	color:white;
	font-size:7px;
	opacity:0;
	visibility:hidden
}
.input-g__checkboxWP label > span:before {
	content:'';
	position:absolute;
	width:13px;
	height:13px;
	border-radius:2px;
	background:#ddd;
	left:0;
	top:2px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.input-g__checkboxWP label > span > a {
	text-decoration:none
}
.input-g__checkboxWP label input[type="checkbox"]:checked + span:after {
	opacity:1;
	visibility:visible
}
.input-g__checkboxWP label input[type="checkbox"]:checked + span:before {
	background:#E3051B;
	border:1px solid #fff
}
.form-block {
	margin-top:16px;
	text-align:left
}
.form-block__text {
	font-size:13px;
}
.form-block__checkboxRadio {
	margin-top:0
}
/*.form-block__pie {
	font-size:13px;
	letter-spacing:0.08px;
	line-height:1;
	color:silver;
	margin-top:0;
	text-align:left
}
.form-block__pie p {
	margin:0
}*/
.form-block__button {
	margin-top:20px
}
.input-g__lines {
	padding:0;
	margin:0
}
.input-g__lineIn {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.input-g__line {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.input-g__line .input-g {
	width:100%;
	margin-bottom:20px
}
.input-g__lineTwoColumns .input-g {
	width:48%;
	display:inline-block;
	margin-right:4%
}
.input-g__lineTwoColumns .input-g:last-child {
	margin-right:0
}
.input-g__lineTwoColumns .input-g__lineIn {
	width:48%;
	margin-right:4%
}
.input-g__lineTwoColumns .input-g__lineIn:last-child {
	margin-right:0
}
.input-g__lineThreeColumns .input-g {
	width:32%;
	display:inline-block;
	margin-right:2%
}
.input-g__lineThreeColumns .input-g:last-child {
	margin-right:0
}
.input-g__lineThreeColumns .input-g__lineIn {
	width:32%;
	margin-right:2%
}
.input-g__lineThreeColumns .input-g__lineIn:last-child {
	margin-right:0
}
.input-g__lineFourColumns .input-g {
	width:23.5%;
	display:inline-block;
	margin-right:2%
}
.input-g__lineFourColumns .input-g:last-child {
	margin-right:0
}
.input-g__lineFourColumns .input-g__lineIn {
	width:23.5%;
	margin-right:2%
}
.input-g__lineFourColumns .input-g__lineIn:last-child {
	margin-right:0
}
@media (max-width:960px) {
	.input-g__lineFourColumns .input-g {
		width:49%;
		display:inline-block;
		margin-right:2%
	}
	.input-g__lineFourColumns .input-g:nth-child(2n+2) {
		margin-right:0
	}
}
@media (max-width:1024px) {
	.input-g__lineFourColumns .input-g {
		width:100%;
		display:inline-block;
		margin-right:0
	}
	.input-g__lineFourColumns .input-g__lineIn {
		width:100%;
		margin-right:0
	}
	.input-g__lineTwoColumns .input-g {
		width:100%;
		display:inline-block;
		margin-right:0
	}
	.input-g__lineTwoColumns .input-g__lineIn {
		width:100%;
		margin-right:0
	}
	.input-g__lineThreeColumns .input-g {
		width:100%;
		display:inline-block;
		margin-right:0
	}
	.input-g__lineThreeColumns .input-g__lineIn {
		width:100%;
		margin-right:0
	}
	.input-g__title {
		font-size:13px
	}
	.input-g__input {
		font-size:14px !important;
		height:38px !important;
		line-height:38px !important;
		padding:0 12px
	}
	.input-g__input .input__g__input::-webkit-input-placeholder {
		font-size:14px !important
	}
	.input-g__input .input__g__input::-moz-placeholder {
		font-size:14px !important
	}
	.input-g__input .input__g__input:-ms-input-placeholder {
		font-size:14px !important
	}
	.input-g__input .input__g__input::-moz-placeholder {
		font-size:14px !important
	}
	.input-g--textarea .input-g__input {
		height:80px !important
	}
	.input-g__checkboxWP label > span {
		font-size:12px
	}
}
.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=tel], .intl-tel-input.separate-dial-code input[type=text] {
	padding-left:52px !important
}
.country-list {
	width:320px !important
}
@media (min-width:1600px) and (max-width:2600px) {
	/*.title-g__fz--1 {
		font-size:1px
	}
	.title-g__fz--2 {
		font-size:2px
	}
	.title-g__fz--3 {
		font-size:3px
	}
	.title-g__fz--4 {
		font-size:4px
	}
	.title-g__fz--5 {
		font-size:5px
	}
	.title-g__fz--6 {
		font-size:6px
	}
	.title-g__fz--7 {
		font-size:7px
	}
	.title-g__fz--8 {
		font-size:8px
	}
	.title-g__fz--9 {
		font-size:9px
	}
	.title-g__fz--10 {
		font-size:10px
	}
	.title-g__fz--11 {
		font-size:11px
	}
	.title-g__fz--12 {
		font-size:12px
	}
	.title-g__fz--13 {
		font-size:13px
	}*/
	.title-g__fz--14 {
		font-size:14px
	}
	.title-g__fz--15 {
		font-size:15px
	}
	.title-g__fz--16 {
		font-size:16px
	}
	.title-g__fz--17 {
		font-size:17px
	}
	.title-g__fz--18 {
		font-size:18px
	}
	.title-g__fz--19 {
		font-size:19px
	}
	.title-g__fz--20 {
		font-size:20px
	}
	.title-g__fz--21 {
		font-size:21px
	}
	.title-g__fz--22 {
		font-size:22px
	}
	.title-g__fz--23 {
		font-size:23px
	}
	.title-g__fz--24 {
		font-size:24px
	}
	.title-g__fz--25 {
		font-size:25px
	}
	.title-g__fz--26 {
		font-size:26px
	}
	.title-g__fz--27 {
		font-size:27px
	}
	.title-g__fz--28 {
		font-size:28px
	}
	.title-g__fz--29 {
		font-size:29px
	}
	.title-g__fz--31 {
		font-size:31px
	}
	.title-g__fz--32 {
		font-size:32px
	}
	.title-g__fz--33 {
		font-size:33px
	}
	.title-g__fz--34 {
		font-size:34px
	}
	.title-g__fz--35 {
		font-size:35px
	}
	.title-g__fz--36 {
		font-size:36px
	}
	.title-g__fz--37 {
		font-size:37px
	}
	.title-g__fz--38 {
		font-size:38px
	}
	.title-g__fz--39 {
		font-size:39px
	}
	.title-g__fz--40 {
		font-size:40px
	}
	.title-g__fz--41 {
		font-size:41px
	}
	.title-g__fz--42 {
		font-size:42px
	}
	.title-g__fz--43 {
		font-size:43px
	}
	.title-g__fz--44 {
		font-size:44px
	}
	.title-g__fz--45 {
		font-size:45px
	}
	.title-g__fz--46 {
		font-size:46px
	}
	.title-g__fz--47 {
		font-size:47px
	}
	.title-g__fz--48 {
		font-size:48px
	}
	.title-g__fz--49 {
		font-size:49px
	}
	.title-g__fz--50 {
		font-size:50px
	}
	.title-g__fz--51 {
		font-size:51px
	}
	.title-g__fz--52 {
		font-size:52px
	}
	.title-g__fz--53 {
		font-size:53px
	}
	.title-g__fz--54 {
		font-size:54px
	}
	.title-g__fz--55 {
		font-size:55px
	}
	.title-g__fz--56 {
		font-size:56px
	}
	.title-g__fz--57 {
		font-size:57px
	}
	.title-g__fz--58 {
		font-size:58px
	}
	.title-g__fz--59 {
		font-size:59px
	}
	.title-g__fz--60 {
		font-size:60px
	}
	.title-g__fz--61 {
		font-size:61px
	}
	.title-g__fz--62 {
		font-size:62px
	}
	.title-g__fz--63 {
		font-size:63px
	}
	.title-g__fz--64 {
		font-size:64px
	}
	.title-g__fz--65 {
		font-size:65px
	}
	.title-g__fz--66 {
		font-size:66px
	}
	.title-g__fz--67 {
		font-size:67px
	}
	.title-g__fz--68 {
		font-size:68px
	}
	.title-g__fz--69 {
		font-size:69px
	}
	.title-g__fz--70 {
		font-size:70px
	}
	.title-g__fz--71 {
		font-size:71px
	}
	.title-g__fz--72 {
		font-size:72px
	}
	/*.title-g__fz--73 {
		font-size:73px
	}
	.title-g__fz--74 {
		font-size:74px
	}
	.title-g__fz--75 {
		font-size:75px
	}
	.title-g__fz--76 {
		font-size:76px
	}
	.title-g__fz--77 {
		font-size:77px
	}
	.title-g__fz--78 {
		font-size:78px
	}
	.title-g__fz--79 {
		font-size:79px
	}
	.title-g__fz--80 {
		font-size:80px
	}
	.title-g__fz--81 {
		font-size:81px
	}
	.title-g__fz--82 {
		font-size:82px
	}
	.title-g__fz--83 {
		font-size:83px
	}
	.title-g__fz--84 {
		font-size:84px
	}
	.title-g__fz--85 {
		font-size:85px
	}
	.title-g__fz--86 {
		font-size:86px
	}
	.title-g__fz--87 {
		font-size:87px
	}
	.title-g__fz--88 {
		font-size:88px
	}
	.title-g__fz--89 {
		font-size:89px
	}
	.title-g__fz--90 {
		font-size:90px
	}
	.title-g__fz--91 {
		font-size:91px
	}
	.title-g__fz--92 {
		font-size:92px
	}
	.title-g__fz--93 {
		font-size:93px
	}
	.title-g__fz--94 {
		font-size:94px
	}
	.title-g__fz--95 {
		font-size:95px
	}
	.title-g__fz--96 {
		font-size:96px
	}
	.title-g__fz--97 {
		font-size:97px
	}
	.title-g__fz--98 {
		font-size:98px
	}
	.title-g__fz--99 {
		font-size:99px
	}
	.title-g__fz--100 {
		font-size:100px
	}*/
}
@media (min-width:1200px) and (max-width:1599px) {
	/*.title-g__fz--1 {
		font-size:1px
	}
	.title-g__fz--2 {
		font-size:2px
	}
	.title-g__fz--3 {
		font-size:3px
	}
	.title-g__fz--4 {
		font-size:4px
	}
	.title-g__fz--5 {
		font-size:5px
	}
	.title-g__fz--6 {
		font-size:6px
	}
	.title-g__fz--7 {
		font-size:7px
	}
	.title-g__fz--8 {
		font-size:8px
	}
	.title-g__fz--9 {
		font-size:9px
	}
	.title-g__fz--10 {
		font-size:10px
	}
	.title-g__fz--11 {
		font-size:11px
	}
	.title-g__fz--12 {
		font-size:12px
	}
	.title-g__fz--13 {
		font-size:13px
	}*/
	.title-g__fz--14 {
		font-size:14px
	}
	.title-g__fz--15 {
		font-size:15px
	}
	.title-g__fz--16 {
		font-size:16px
	}
	.title-g__fz--17 {
		font-size:17px
	}
	.title-g__fz--18 {
		font-size:18px
	}
	.title-g__fz--19 {
		font-size:19px
	}
	.title-g__fz--20 {
		font-size:20px
	}
	.title-g__fz--21 {
		font-size:21px
	}
	.title-g__fz--22 {
		font-size:22px
	}
	.title-g__fz--23 {
		font-size:23px
	}
	.title-g__fz--24 {
		font-size:24px
	}
	.title-g__fz--25 {
		font-size:25px
	}
	.title-g__fz--26 {
		font-size:26px
	}
	.title-g__fz--27 {
		font-size:27px
	}
	.title-g__fz--28 {
		font-size:28px
	}
	.title-g__fz--29 {
		font-size:29px
	}
	.title-g__fz--30 {
		font-size:30px
	}
	.title-g__fz--31 {
		font-size:31px
	}
	.title-g__fz--32 {
		font-size:32px
	}
	.title-g__fz--33 {
		font-size:33px
	}
	.title-g__fz--34 {
		font-size:34px
	}
	.title-g__fz--35 {
		font-size:35px
	}
	.title-g__fz--36 {
		font-size:36px
	}
	.title-g__fz--37 {
		font-size:37px
	}
	.title-g__fz--38 {
		font-size:38px
	}
	.title-g__fz--39 {
		font-size:39px
	}
	.title-g__fz--40 {
		font-size:40px
	}
	.title-g__fz--41 {
		font-size:41px
	}
	.title-g__fz--42 {
		font-size:42px
	}
	.title-g__fz--43 {
		font-size:43px
	}
	.title-g__fz--44 {
		font-size:44px
	}
	.title-g__fz--45 {
		font-size:45px
	}
	.title-g__fz--46 {
		font-size:46px
	}
	.title-g__fz--47 {
		font-size:47px
	}
	.title-g__fz--48 {
		font-size:48px
	}
	.title-g__fz--49 {
		font-size:49px
	}
	.title-g__fz--50 {
		font-size:50px
	}
	.title-g__fz--51 {
		font-size:51px
	}
	.title-g__fz--52 {
		font-size:52px
	}
	.title-g__fz--53 {
		font-size:53px
	}
	.title-g__fz--54 {
		font-size:54px
	}
	.title-g__fz--55 {
		font-size:55px
	}
	.title-g__fz--56 {
		font-size:56px
	}
	.title-g__fz--57 {
		font-size:57px
	}
	.title-g__fz--58 {
		font-size:58px
	}
	.title-g__fz--59 {
		font-size:59px
	}
	.title-g__fz--60 {
		font-size:60px
	}
	.title-g__fz--61 {
		font-size:61px
	}
	.title-g__fz--62 {
		font-size:62px
	}
	.title-g__fz--63 {
		font-size:63px
	}
	.title-g__fz--64 {
		font-size:64px
	}
	.title-g__fz--65 {
		font-size:65px
	}
	.title-g__fz--66 {
		font-size:66px
	}
	.title-g__fz--67 {
		font-size:67px
	}
	.title-g__fz--68 {
		font-size:68px
	}
	.title-g__fz--69 {
		font-size:69px
	}
	.title-g__fz--70 {
		font-size:70px
	}
	.title-g__fz--71 {
		font-size:71px
	}
	.title-g__fz--72 {
		font-size:72px
	}
	/*.title-g__fz--73 {
		font-size:73px
	}
	.title-g__fz--74 {
		font-size:74px
	}
	.title-g__fz--75 {
		font-size:75px
	}
	.title-g__fz--76 {
		font-size:76px
	}
	.title-g__fz--77 {
		font-size:77px
	}
	.title-g__fz--78 {
		font-size:78px
	}
	.title-g__fz--79 {
		font-size:79px
	}
	.title-g__fz--80 {
		font-size:80px
	}
	.title-g__fz--81 {
		font-size:81px
	}
	.title-g__fz--82 {
		font-size:82px
	}
	.title-g__fz--83 {
		font-size:83px
	}
	.title-g__fz--84 {
		font-size:84px
	}
	.title-g__fz--85 {
		font-size:85px
	}
	.title-g__fz--86 {
		font-size:86px
	}
	.title-g__fz--87 {
		font-size:87px
	}
	.title-g__fz--88 {
		font-size:88px
	}
	.title-g__fz--89 {
		font-size:89px
	}
	.title-g__fz--90 {
		font-size:90px
	}
	.title-g__fz--91 {
		font-size:91px
	}
	.title-g__fz--92 {
		font-size:92px
	}
	.title-g__fz--93 {
		font-size:93px
	}
	.title-g__fz--94 {
		font-size:94px
	}
	.title-g__fz--95 {
		font-size:95px
	}
	.title-g__fz--96 {
		font-size:96px
	}
	.title-g__fz--97 {
		font-size:97px
	}
	.title-g__fz--98 {
		font-size:98px
	}
	.title-g__fz--99 {
		font-size:99px
	}
	.title-g__fz--100 {
		font-size:100px
	}*/
}
@media (min-width:769px) and (max-width:1199px) {
	/*.title-g__fz--1 {
		font-size:1px
	}
	.title-g__fz--2 {
		font-size:2px
	}
	.title-g__fz--3 {
		font-size:3px
	}
	.title-g__fz--4 {
		font-size:4px
	}
	.title-g__fz--5 {
		font-size:5px
	}
	.title-g__fz--6 {
		font-size:6px
	}
	.title-g__fz--7 {
		font-size:7px
	}
	.title-g__fz--8 {
		font-size:8px
	}
	.title-g__fz--9 {
		font-size:9px
	}
	.title-g__fz--10 {
		font-size:10px
	}
	.title-g__fz--11 {
		font-size:11px
	}
	.title-g__fz--12 {
		font-size:12px
	}
	.title-g__fz--13 {
		font-size:13px
	}*/
	.title-g__fz--14 {
		font-size:14px
	}
	.title-g__fz--15 {
		font-size:15px
	}
	.title-g__fz--16 {
		font-size:16px
	}
	.title-g__fz--17 {
		font-size:17px
	}
	.title-g__fz--18 {
		font-size:18px
	}
	.title-g__fz--19 {
		font-size:19px
	}
	.title-g__fz--20 {
		font-size:20px
	}
	.title-g__fz--21 {
		font-size:21px
	}
	.title-g__fz--22 {
		font-size:22px
	}
	.title-g__fz--23 {
		font-size:23px
	}
	.title-g__fz--24 {
		font-size:24px
	}
	.title-g__fz--25 {
		font-size:25px
	}
	.title-g__fz--26 {
		font-size:26px
	}
	.title-g__fz--27 {
		font-size:27px
	}
	.title-g__fz--28 {
		font-size:28px
	}
	.title-g__fz--29 {
		font-size:29px
	}
	.title-g__fz--30 {
		font-size:30px
	}
	.title-g__fz--31 {
		font-size:31px
	}
	.title-g__fz--32 {
		font-size:32px
	}
	.title-g__fz--33 {
		font-size:33px
	}
	.title-g__fz--34 {
		font-size:34px
	}
	.title-g__fz--35 {
		font-size:35px
	}
	.title-g__fz--36 {
		font-size:36px
	}
	.title-g__fz--37 {
		font-size:37px
	}
	.title-g__fz--38 {
		font-size:38px
	}
	.title-g__fz--39 {
		font-size:39px
	}
	.title-g__fz--40 {
		font-size:40px
	}
	.title-g__fz--41 {
		font-size:41px
	}
	.title-g__fz--42 {
		font-size:42px
	}
	.title-g__fz--43 {
		font-size:43px
	}
	.title-g__fz--44 {
		font-size:44px
	}
	.title-g__fz--45 {
		font-size:45px
	}
	.title-g__fz--46 {
		font-size:46px
	}
	.title-g__fz--47 {
		font-size:47px
	}
	.title-g__fz--48 {
		font-size:48px
	}
	.title-g__fz--49 {
		font-size:49px
	}
	.title-g__fz--50 {
		font-size:50px
	}
	.title-g__fz--51 {
		font-size:51px
	}
	.title-g__fz--52 {
		font-size:52px
	}
	.title-g__fz--53 {
		font-size:53px
	}
	.title-g__fz--54 {
		font-size:54px
	}
	.title-g__fz--55 {
		font-size:55px
	}
	.title-g__fz--56 {
		font-size:56px
	}
	.title-g__fz--57 {
		font-size:57px
	}
	.title-g__fz--58 {
		font-size:58px
	}
	.title-g__fz--59 {
		font-size:59px
	}
	.title-g__fz--60 {
		font-size:60px
	}
	.title-g__fz--61 {
		font-size:61px
	}
	.title-g__fz--62 {
		font-size:62px
	}
	.title-g__fz--63 {
		font-size:63px
	}
	.title-g__fz--64 {
		font-size:64px
	}
	.title-g__fz--65 {
		font-size:65px
	}
	.title-g__fz--66 {
		font-size:66px
	}
	.title-g__fz--67 {
		font-size:67px
	}
	.title-g__fz--68 {
		font-size:68px
	}
	.title-g__fz--69 {
		font-size:69px
	}
	.title-g__fz--70 {
		font-size:70px
	}
	.title-g__fz--71 {
		font-size:71px
	}
	.title-g__fz--72 {
		font-size:72px
	}
	/*.title-g__fz--73 {
		font-size:73px
	}
	.title-g__fz--74 {
		font-size:74px
	}
	.title-g__fz--75 {
		font-size:75px
	}
	.title-g__fz--76 {
		font-size:76px
	}
	.title-g__fz--77 {
		font-size:77px
	}
	.title-g__fz--78 {
		font-size:78px
	}
	.title-g__fz--79 {
		font-size:79px
	}
	.title-g__fz--80 {
		font-size:80px
	}
	.title-g__fz--81 {
		font-size:81px
	}
	.title-g__fz--82 {
		font-size:82px
	}
	.title-g__fz--83 {
		font-size:83px
	}
	.title-g__fz--84 {
		font-size:84px
	}
	.title-g__fz--85 {
		font-size:85px
	}
	.title-g__fz--86 {
		font-size:86px
	}
	.title-g__fz--87 {
		font-size:87px
	}
	.title-g__fz--88 {
		font-size:88px
	}
	.title-g__fz--89 {
		font-size:89px
	}
	.title-g__fz--90 {
		font-size:90px
	}
	.title-g__fz--91 {
		font-size:91px
	}
	.title-g__fz--92 {
		font-size:92px
	}
	.title-g__fz--93 {
		font-size:93px
	}
	.title-g__fz--94 {
		font-size:94px
	}
	.title-g__fz--95 {
		font-size:95px
	}
	.title-g__fz--96 {
		font-size:96px
	}
	.title-g__fz--97 {
		font-size:97px
	}
	.title-g__fz--98 {
		font-size:98px
	}
	.title-g__fz--99 {
		font-size:99px
	}
	.title-g__fz--100 {
		font-size:100px
	}*/
}
@media (min-width:768px) and (max-width:1024px) {
	/*.title-g__fz--1 {
		font-size:30px
	}
	.title-g__fz--2 {
		font-size:30px
	}
	.title-g__fz--3 {
		font-size:30px
	}
	.title-g__fz--4 {
		font-size:30px
	}
	.title-g__fz--5 {
		font-size:30px
	}
	.title-g__fz--6 {
		font-size:30px
	}
	.title-g__fz--7 {
		font-size:30px
	}
	.title-g__fz--8 {
		font-size:30px
	}
	.title-g__fz--9 {
		font-size:30px
	}
	.title-g__fz--10 {
		font-size:30px
	}
	.title-g__fz--11 {
		font-size:30px
	}
	.title-g__fz--12 {
		font-size:30px
	}
	.title-g__fz--13 {
		font-size:30px
	}*/
	.title-g__fz--14 {
		font-size:30px
	}
	.title-g__fz--15 {
		font-size:30px
	}
	.title-g__fz--16 {
		font-size:30px
	}
	.title-g__fz--17 {
		font-size:30px
	}
	.title-g__fz--18 {
		font-size:30px
	}
	.title-g__fz--19 {
		font-size:30px
	}
	.title-g__fz--20 {
		font-size:30px
	}
	.title-g__fz--21 {
		font-size:30px
	}
	.title-g__fz--22 {
		font-size:30px
	}
	.title-g__fz--23 {
		font-size:30px
	}
	.title-g__fz--24 {
		font-size:30px
	}
	.title-g__fz--25 {
		font-size:30px
	}
	.title-g__fz--26 {
		font-size:30px
	}
	.title-g__fz--27 {
		font-size:30px
	}
	.title-g__fz--28 {
		font-size:30px
	}
	.title-g__fz--29 {
		font-size:30px
	}
	.title-g__fz--30 {
		font-size:30px
	}
	.title-g__fz--31 {
		font-size:30px
	}
	.title-g__fz--32 {
		font-size:30px
	}
	.title-g__fz--33 {
		font-size:30px
	}
	.title-g__fz--34 {
		font-size:30px
	}
	.title-g__fz--35 {
		font-size:30px
	}
	.title-g__fz--36 {
		font-size:30px
	}
	.title-g__fz--37 {
		font-size:30px
	}
	.title-g__fz--38 {
		font-size:30px
	}
	.title-g__fz--39 {
		font-size:30px
	}
	.title-g__fz--40 {
		font-size:30px
	}
	.title-g__fz--41 {
		font-size:30px
	}
	.title-g__fz--42 {
		font-size:30px
	}
	.title-g__fz--43 {
		font-size:30px
	}
	.title-g__fz--44 {
		font-size:30px
	}
	.title-g__fz--45 {
		font-size:30px
	}
	.title-g__fz--46 {
		font-size:30px
	}
	.title-g__fz--47 {
		font-size:30px
	}
	.title-g__fz--48 {
		font-size:30px
	}
	.title-g__fz--49 {
		font-size:30px
	}
	.title-g__fz--50 {
		font-size:30px
	}
	.title-g__fz--51 {
		font-size:30px
	}
	.title-g__fz--52 {
		font-size:30px
	}
	.title-g__fz--53 {
		font-size:30px
	}
	.title-g__fz--54 {
		font-size:30px
	}
	.title-g__fz--55 {
		font-size:30px
	}
	.title-g__fz--56 {
		font-size:30px
	}
	.title-g__fz--57 {
		font-size:30px
	}
	.title-g__fz--58 {
		font-size:30px
	}
	.title-g__fz--59 {
		font-size:30px
	}
	.title-g__fz--60 {
		font-size:30px
	}
	.title-g__fz--61 {
		font-size:30px
	}
	.title-g__fz--62 {
		font-size:30px
	}
	.title-g__fz--63 {
		font-size:30px
	}
	.title-g__fz--64 {
		font-size:30px
	}
	.title-g__fz--65 {
		font-size:30px
	}
	.title-g__fz--66 {
		font-size:30px
	}
	.title-g__fz--67 {
		font-size:30px
	}
	.title-g__fz--68 {
		font-size:30px
	}
	.title-g__fz--69 {
		font-size:30px
	}
	.title-g__fz--70 {
		font-size:30px
	}
	.title-g__fz--71 {
		font-size:30px
	}
	.title-g__fz--72 {
		font-size:30px
	}
	/*.title-g__fz--73 {
		font-size:30px
	}
	.title-g__fz--74 {
		font-size:30px
	}
	.title-g__fz--75 {
		font-size:30px
	}
	.title-g__fz--76 {
		font-size:30px
	}
	.title-g__fz--77 {
		font-size:30px
	}
	.title-g__fz--78 {
		font-size:30px
	}
	.title-g__fz--79 {
		font-size:30px
	}
	.title-g__fz--80 {
		font-size:30px
	}
	.title-g__fz--81 {
		font-size:30px
	}
	.title-g__fz--82 {
		font-size:30px
	}
	.title-g__fz--83 {
		font-size:30px
	}
	.title-g__fz--84 {
		font-size:30px
	}
	.title-g__fz--85 {
		font-size:30px
	}
	.title-g__fz--86 {
		font-size:30px
	}
	.title-g__fz--87 {
		font-size:30px
	}
	.title-g__fz--88 {
		font-size:30px
	}
	.title-g__fz--89 {
		font-size:30px
	}
	.title-g__fz--90 {
		font-size:30px
	}
	.title-g__fz--91 {
		font-size:30px
	}
	.title-g__fz--92 {
		font-size:30px
	}
	.title-g__fz--93 {
		font-size:30px
	}
	.title-g__fz--94 {
		font-size:30px
	}
	.title-g__fz--95 {
		font-size:30px
	}
	.title-g__fz--96 {
		font-size:30px
	}
	.title-g__fz--97 {
		font-size:30px
	}
	.title-g__fz--98 {
		font-size:30px
	}
	.title-g__fz--99 {
		font-size:30px
	}
	.title-g__fz--100 {
		font-size:30px
	}*/
}
@media (min-width:320px) and (max-width:1024px) {
	/*.title-g__fz--1 {
		font-size:23px
	}
	.title-g__fz--2 {
		font-size:23px
	}
	.title-g__fz--3 {
		font-size:23px
	}
	.title-g__fz--4 {
		font-size:23px
	}
	.title-g__fz--5 {
		font-size:23px
	}
	.title-g__fz--6 {
		font-size:23px
	}
	.title-g__fz--7 {
		font-size:23px
	}
	.title-g__fz--8 {
		font-size:23px
	}
	.title-g__fz--9 {
		font-size:23px
	}
	.title-g__fz--10 {
		font-size:23px
	}
	.title-g__fz--11 {
		font-size:23px
	}
	.title-g__fz--12 {
		font-size:23px
	}
	.title-g__fz--13 {
		font-size:23px
	}*/
	.title-g__fz--14 {
		font-size:23px
	}
	.title-g__fz--15 {
		font-size:23px
	}
	.title-g__fz--16 {
		font-size:23px
	}
	.title-g__fz--17 {
		font-size:23px
	}
	.title-g__fz--18 {
		font-size:23px
	}
	.title-g__fz--19 {
		font-size:23px
	}
	.title-g__fz--20 {
		font-size:23px
	}
	.title-g__fz--21 {
		font-size:23px
	}
	.title-g__fz--22 {
		font-size:23px
	}
	.title-g__fz--23 {
		font-size:23px
	}
	.title-g__fz--24 {
		font-size:23px
	}
	.title-g__fz--25 {
		font-size:23px
	}
	.title-g__fz--26 {
		font-size:23px
	}
	.title-g__fz--27 {
		font-size:23px
	}
	.title-g__fz--28 {
		font-size:23px
	}
	.title-g__fz--29 {
		font-size:23px
	}
	.title-g__fz--30 {
		font-size:23px
	}
	.title-g__fz--31 {
		font-size:23px
	}
	.title-g__fz--32 {
		font-size:23px
	}
	.title-g__fz--33 {
		font-size:23px
	}
	.title-g__fz--34 {
		font-size:23px
	}
	.title-g__fz--35 {
		font-size:23px
	}
	.title-g__fz--36 {
		font-size:23px
	}
	.title-g__fz--37 {
		font-size:23px
	}
	.title-g__fz--38 {
		font-size:30px
	}
	.title-g__fz--39 {
		font-size:23px
	}
	.title-g__fz--40 {
		font-size:23px
	}
	.title-g__fz--41 {
		font-size:23px
	}
	.title-g__fz--42 {
		font-size:23px
	}
	.title-g__fz--43 {
		font-size:23px
	}
	.title-g__fz--44 {
		font-size:23px
	}
	.title-g__fz--45 {
		font-size:23px
	}
	.title-g__fz--46 {
		font-size:23px
	}
	.title-g__fz--47 {
		font-size:23px
	}
	.title-g__fz--48 {
		font-size:23px
	}
	.title-g__fz--49 {
		font-size:23px
	}
	.title-g__fz--50 {
		font-size:23px
	}
	.title-g__fz--51 {
		font-size:23px
	}
	.title-g__fz--52 {
		font-size:23px
	}
	.title-g__fz--53 {
		font-size:23px
	}
	.title-g__fz--54 {
		font-size:23px
	}
	.title-g__fz--55 {
		font-size:23px
	}
	.title-g__fz--56 {
		font-size:23px
	}
	.title-g__fz--57 {
		font-size:23px
	}
	.title-g__fz--58 {
		font-size:23px
	}
	.title-g__fz--59 {
		font-size:23px
	}
	.title-g__fz--60 {
		font-size:23px
	}
	.title-g__fz--61 {
		font-size:23px
	}
	.title-g__fz--62 {
		font-size:23px
	}
	.title-g__fz--63 {
		font-size:23px
	}
	.title-g__fz--64 {
		font-size:23px
	}
	.title-g__fz--65 {
		font-size:23px
	}
	.title-g__fz--66 {
		font-size:23px
	}
	.title-g__fz--67 {
		font-size:23px
	}
	.title-g__fz--68 {
		font-size:23px
	}
	.title-g__fz--69 {
		font-size:23px
	}
	.title-g__fz--70 {
		font-size:23px
	}
	.title-g__fz--71 {
		font-size:23px
	}
	.title-g__fz--72 {
		font-size:23px
	}
	/*.title-g__fz--73 {
		font-size:23px
	}
	.title-g__fz--74 {
		font-size:23px
	}
	.title-g__fz--75 {
		font-size:23px
	}
	.title-g__fz--76 {
		font-size:23px
	}
	.title-g__fz--77 {
		font-size:23px
	}
	.title-g__fz--78 {
		font-size:23px
	}
	.title-g__fz--79 {
		font-size:23px
	}
	.title-g__fz--80 {
		font-size:23px
	}
	.title-g__fz--81 {
		font-size:23px
	}
	.title-g__fz--82 {
		font-size:23px
	}
	.title-g__fz--83 {
		font-size:23px
	}
	.title-g__fz--84 {
		font-size:23px
	}
	.title-g__fz--85 {
		font-size:23px
	}
	.title-g__fz--86 {
		font-size:23px
	}
	.title-g__fz--87 {
		font-size:23px
	}
	.title-g__fz--88 {
		font-size:23px
	}
	.title-g__fz--89 {
		font-size:23px
	}
	.title-g__fz--90 {
		font-size:23px
	}
	.title-g__fz--91 {
		font-size:23px
	}
	.title-g__fz--92 {
		font-size:23px
	}
	.title-g__fz--93 {
		font-size:23px
	}
	.title-g__fz--94 {
		font-size:23px
	}
	.title-g__fz--95 {
		font-size:23px
	}
	.title-g__fz--96 {
		font-size:23px
	}
	.title-g__fz--97 {
		font-size:23px
	}
	.title-g__fz--98 {
		font-size:23px
	}
	.title-g__fz--99 {
		font-size:23px
	}
	.title-g__fz--100 {
		font-size:23px
	}*/
}
@media (min-width:1600px) and (max-width:2600px) {
	/*.titleText-g__fz--1 {
		font-size:1px
	}
	.titleText-g__fz--2 {
		font-size:2px
	}
	.titleText-g__fz--3 {
		font-size:3px
	}
	.titleText-g__fz--4 {
		font-size:4px
	}
	.titleText-g__fz--5 {
		font-size:5px
	}
	.titleText-g__fz--6 {
		font-size:6px
	}
	.titleText-g__fz--7 {
		font-size:7px
	}
	.titleText-g__fz--8 {
		font-size:8px
	}
	.titleText-g__fz--9 {
		font-size:9px
	}
	.titleText-g__fz--10 {
		font-size:10px
	}
	.titleText-g__fz--11 {
		font-size:11px
	}
	.titleText-g__fz--12 {
		font-size:12px
	}
	.titleText-g__fz--13 {
		font-size:13px
	}*/
	.titleText-g__fz--14 {
		font-size:14px
	}
	.titleText-g__fz--15 {
		font-size:15px
	}
	.titleText-g__fz--16 {
		font-size:16px
	}
	.titleText-g__fz--17 {
		font-size:17px
	}
	.titleText-g__fz--18 {
		font-size:18px
	}
	.titleText-g__fz--19 {
		font-size:19px
	}
	.titleText-g__fz--20 {
		font-size:20px
	}
	/*.titleText-g__fz--21 {
		font-size:21px
	}
	.titleText-g__fz--22 {
		font-size:22px
	}
	.titleText-g__fz--23 {
		font-size:23px
	}
	.titleText-g__fz--24 {
		font-size:24px
	}
	.titleText-g__fz--25 {
		font-size:25px
	}
	.titleText-g__fz--26 {
		font-size:26px
	}
	.titleText-g__fz--27 {
		font-size:27px
	}
	.titleText-g__fz--28 {
		font-size:28px
	}
	.titleText-g__fz--29 {
		font-size:29px
	}
	.titleText-g__fz--30 {
		font-size:30px
	}
	.titleText-g__fz--31 {
		font-size:31px
	}
	.titleText-g__fz--32 {
		font-size:32px
	}
	.titleText-g__fz--33 {
		font-size:33px
	}
	.titleText-g__fz--34 {
		font-size:34px
	}
	.titleText-g__fz--35 {
		font-size:35px
	}
	.titleText-g__fz--36 {
		font-size:36px
	}
	.titleText-g__fz--37 {
		font-size:37px
	}
	.titleText-g__fz--38 {
		font-size:38px
	}
	.titleText-g__fz--39 {
		font-size:39px
	}
	.titleText-g__fz--40 {
		font-size:40px
	}
	.titleText-g__fz--41 {
		font-size:41px
	}
	.titleText-g__fz--42 {
		font-size:42px
	}
	.titleText-g__fz--43 {
		font-size:43px
	}
	.titleText-g__fz--44 {
		font-size:44px
	}
	.titleText-g__fz--45 {
		font-size:45px
	}
	.titleText-g__fz--46 {
		font-size:46px
	}
	.titleText-g__fz--47 {
		font-size:47px
	}
	.titleText-g__fz--48 {
		font-size:48px
	}
	.titleText-g__fz--49 {
		font-size:49px
	}
	.titleText-g__fz--50 {
		font-size:50px
	}
	.titleText-g__fz--51 {
		font-size:51px
	}
	.titleText-g__fz--52 {
		font-size:52px
	}
	.titleText-g__fz--53 {
		font-size:53px
	}
	.titleText-g__fz--54 {
		font-size:54px
	}
	.titleText-g__fz--55 {
		font-size:55px
	}
	.titleText-g__fz--56 {
		font-size:56px
	}
	.titleText-g__fz--57 {
		font-size:57px
	}
	.titleText-g__fz--58 {
		font-size:58px
	}
	.titleText-g__fz--59 {
		font-size:59px
	}
	.titleText-g__fz--60 {
		font-size:60px
	}
	.titleText-g__fz--61 {
		font-size:61px
	}
	.titleText-g__fz--62 {
		font-size:62px
	}
	.titleText-g__fz--63 {
		font-size:63px
	}
	.titleText-g__fz--64 {
		font-size:64px
	}
	.titleText-g__fz--65 {
		font-size:65px
	}
	.titleText-g__fz--66 {
		font-size:66px
	}
	.titleText-g__fz--67 {
		font-size:67px
	}
	.titleText-g__fz--68 {
		font-size:68px
	}
	.titleText-g__fz--69 {
		font-size:69px
	}
	.titleText-g__fz--70 {
		font-size:70px
	}
	.titleText-g__fz--71 {
		font-size:71px
	}
	.titleText-g__fz--72 {
		font-size:72px
	}
	.titleText-g__fz--73 {
		font-size:73px
	}
	.titleText-g__fz--74 {
		font-size:74px
	}
	.titleText-g__fz--75 {
		font-size:75px
	}
	.titleText-g__fz--76 {
		font-size:76px
	}
	.titleText-g__fz--77 {
		font-size:77px
	}
	.titleText-g__fz--78 {
		font-size:78px
	}
	.titleText-g__fz--79 {
		font-size:79px
	}
	.titleText-g__fz--80 {
		font-size:80px
	}
	.titleText-g__fz--81 {
		font-size:81px
	}
	.titleText-g__fz--82 {
		font-size:82px
	}
	.titleText-g__fz--83 {
		font-size:83px
	}
	.titleText-g__fz--84 {
		font-size:84px
	}
	.titleText-g__fz--85 {
		font-size:85px
	}
	.titleText-g__fz--86 {
		font-size:86px
	}
	.titleText-g__fz--87 {
		font-size:87px
	}
	.titleText-g__fz--88 {
		font-size:88px
	}
	.titleText-g__fz--89 {
		font-size:89px
	}
	.titleText-g__fz--90 {
		font-size:90px
	}
	.titleText-g__fz--91 {
		font-size:91px
	}
	.titleText-g__fz--92 {
		font-size:92px
	}
	.titleText-g__fz--93 {
		font-size:93px
	}
	.titleText-g__fz--94 {
		font-size:94px
	}
	.titleText-g__fz--95 {
		font-size:95px
	}
	.titleText-g__fz--96 {
		font-size:96px
	}
	.titleText-g__fz--97 {
		font-size:97px
	}
	.titleText-g__fz--98 {
		font-size:98px
	}
	.titleText-g__fz--99 {
		font-size:99px
	}
	.titleText-g__fz--100 {
		font-size:100px
	}*/
}
@media (min-width:1200px) and (max-width:1599px) {
	/*.titleText-g__fz--1 {
		font-size:1px
	}
	.titleText-g__fz--2 {
		font-size:2px
	}
	.titleText-g__fz--3 {
		font-size:3px
	}
	.titleText-g__fz--4 {
		font-size:4px
	}
	.titleText-g__fz--5 {
		font-size:5px
	}
	.titleText-g__fz--6 {
		font-size:6px
	}
	.titleText-g__fz--7 {
		font-size:7px
	}
	.titleText-g__fz--8 {
		font-size:8px
	}
	.titleText-g__fz--9 {
		font-size:9px
	}
	.titleText-g__fz--10 {
		font-size:10px
	}
	.titleText-g__fz--11 {
		font-size:11px
	}
	.titleText-g__fz--12 {
		font-size:12px
	}
	.titleText-g__fz--13 {
		font-size:13px
	}*/
	.titleText-g__fz--14 {
		font-size:14px
	}
	.titleText-g__fz--15 {
		font-size:15px
	}
	.titleText-g__fz--16 {
		font-size:16px
	}
	.titleText-g__fz--17 {
		font-size:17px
	}
	.titleText-g__fz--18 {
		font-size:18px
	}
	.titleText-g__fz--19 {
		font-size:19px
	}
	.titleText-g__fz--20 {
		font-size:20px
	}
	/*.titleText-g__fz--21 {
		font-size:21px
	}
	.titleText-g__fz--22 {
		font-size:22px
	}
	.titleText-g__fz--23 {
		font-size:23px
	}
	.titleText-g__fz--24 {
		font-size:24px
	}
	.titleText-g__fz--25 {
		font-size:25px
	}
	.titleText-g__fz--26 {
		font-size:26px
	}
	.titleText-g__fz--27 {
		font-size:27px
	}
	.titleText-g__fz--28 {
		font-size:28px
	}
	.titleText-g__fz--29 {
		font-size:29px
	}
	.titleText-g__fz--30 {
		font-size:30px
	}
	.titleText-g__fz--31 {
		font-size:31px
	}
	.titleText-g__fz--32 {
		font-size:32px
	}
	.titleText-g__fz--33 {
		font-size:33px
	}
	.titleText-g__fz--34 {
		font-size:34px
	}
	.titleText-g__fz--35 {
		font-size:35px
	}
	.titleText-g__fz--36 {
		font-size:36px
	}
	.titleText-g__fz--37 {
		font-size:37px
	}
	.titleText-g__fz--38 {
		font-size:38px
	}
	.titleText-g__fz--39 {
		font-size:39px
	}
	.titleText-g__fz--40 {
		font-size:40px
	}
	.titleText-g__fz--41 {
		font-size:41px
	}
	.titleText-g__fz--42 {
		font-size:42px
	}
	.titleText-g__fz--43 {
		font-size:43px
	}
	.titleText-g__fz--44 {
		font-size:44px
	}
	.titleText-g__fz--45 {
		font-size:45px
	}
	.titleText-g__fz--46 {
		font-size:46px
	}
	.titleText-g__fz--47 {
		font-size:47px
	}
	.titleText-g__fz--48 {
		font-size:48px
	}
	.titleText-g__fz--49 {
		font-size:49px
	}
	.titleText-g__fz--50 {
		font-size:50px
	}
	.titleText-g__fz--51 {
		font-size:51px
	}
	.titleText-g__fz--52 {
		font-size:52px
	}
	.titleText-g__fz--53 {
		font-size:53px
	}
	.titleText-g__fz--54 {
		font-size:54px
	}
	.titleText-g__fz--55 {
		font-size:55px
	}
	.titleText-g__fz--56 {
		font-size:56px
	}
	.titleText-g__fz--57 {
		font-size:57px
	}
	.titleText-g__fz--58 {
		font-size:58px
	}
	.titleText-g__fz--59 {
		font-size:59px
	}
	.titleText-g__fz--60 {
		font-size:60px
	}
	.titleText-g__fz--61 {
		font-size:61px
	}
	.titleText-g__fz--62 {
		font-size:62px
	}
	.titleText-g__fz--63 {
		font-size:63px
	}
	.titleText-g__fz--64 {
		font-size:64px
	}
	.titleText-g__fz--65 {
		font-size:65px
	}
	.titleText-g__fz--66 {
		font-size:66px
	}
	.titleText-g__fz--67 {
		font-size:67px
	}
	.titleText-g__fz--68 {
		font-size:68px
	}
	.titleText-g__fz--69 {
		font-size:69px
	}
	.titleText-g__fz--70 {
		font-size:70px
	}
	.titleText-g__fz--71 {
		font-size:71px
	}
	.titleText-g__fz--72 {
		font-size:72px
	}
	.titleText-g__fz--73 {
		font-size:73px
	}
	.titleText-g__fz--74 {
		font-size:74px
	}
	.titleText-g__fz--75 {
		font-size:75px
	}
	.titleText-g__fz--76 {
		font-size:76px
	}
	.titleText-g__fz--77 {
		font-size:77px
	}
	.titleText-g__fz--78 {
		font-size:78px
	}
	.titleText-g__fz--79 {
		font-size:79px
	}
	.titleText-g__fz--80 {
		font-size:80px
	}
	.titleText-g__fz--81 {
		font-size:81px
	}
	.titleText-g__fz--82 {
		font-size:82px
	}
	.titleText-g__fz--83 {
		font-size:83px
	}
	.titleText-g__fz--84 {
		font-size:84px
	}
	.titleText-g__fz--85 {
		font-size:85px
	}
	.titleText-g__fz--86 {
		font-size:86px
	}
	.titleText-g__fz--87 {
		font-size:87px
	}
	.titleText-g__fz--88 {
		font-size:88px
	}
	.titleText-g__fz--89 {
		font-size:89px
	}
	.titleText-g__fz--90 {
		font-size:90px
	}
	.titleText-g__fz--91 {
		font-size:91px
	}
	.titleText-g__fz--92 {
		font-size:92px
	}
	.titleText-g__fz--93 {
		font-size:93px
	}
	.titleText-g__fz--94 {
		font-size:94px
	}
	.titleText-g__fz--95 {
		font-size:95px
	}
	.titleText-g__fz--96 {
		font-size:96px
	}
	.titleText-g__fz--97 {
		font-size:97px
	}
	.titleText-g__fz--98 {
		font-size:98px
	}
	.titleText-g__fz--99 {
		font-size:99px
	}
	.titleText-g__fz--100 {
		font-size:100px
	}*/
}
@media (min-width:769px) and (max-width:1199px) {
	/*.titleText-g__fz--1 {
		font-size:1px
	}
	.titleText-g__fz--2 {
		font-size:2px
	}
	.titleText-g__fz--3 {
		font-size:3px
	}
	.titleText-g__fz--4 {
		font-size:4px
	}
	.titleText-g__fz--5 {
		font-size:5px
	}
	.titleText-g__fz--6 {
		font-size:6px
	}
	.titleText-g__fz--7 {
		font-size:7px
	}
	.titleText-g__fz--8 {
		font-size:8px
	}
	.titleText-g__fz--9 {
		font-size:9px
	}
	.titleText-g__fz--10 {
		font-size:10px
	}
	.titleText-g__fz--11 {
		font-size:11px
	}
	.titleText-g__fz--12 {
		font-size:12px
	}
	.titleText-g__fz--13 {
		font-size:13px
	}*/
	.titleText-g__fz--14 {
		font-size:14px
	}
	.titleText-g__fz--15 {
		font-size:15px
	}
	.titleText-g__fz--16 {
		font-size:16px
	}
	.titleText-g__fz--17 {
		font-size:17px
	}
	.titleText-g__fz--18 {
		font-size:18px
	}
	.titleText-g__fz--19 {
		font-size:19px
	}
	.titleText-g__fz--20 {
		font-size:20px
	}
	/*.titleText-g__fz--21 {
		font-size:21px
	}
	.titleText-g__fz--22 {
		font-size:22px
	}
	.titleText-g__fz--23 {
		font-size:23px
	}
	.titleText-g__fz--24 {
		font-size:24px
	}
	.titleText-g__fz--25 {
		font-size:25px
	}
	.titleText-g__fz--26 {
		font-size:26px
	}
	.titleText-g__fz--27 {
		font-size:27px
	}
	.titleText-g__fz--28 {
		font-size:28px
	}
	.titleText-g__fz--29 {
		font-size:29px
	}
	.titleText-g__fz--30 {
		font-size:30px
	}
	.titleText-g__fz--31 {
		font-size:31px
	}
	.titleText-g__fz--32 {
		font-size:32px
	}
	.titleText-g__fz--33 {
		font-size:33px
	}
	.titleText-g__fz--34 {
		font-size:34px
	}
	.titleText-g__fz--35 {
		font-size:35px
	}
	.titleText-g__fz--36 {
		font-size:36px
	}
	.titleText-g__fz--37 {
		font-size:37px
	}
	.titleText-g__fz--38 {
		font-size:38px
	}
	.titleText-g__fz--39 {
		font-size:39px
	}
	.titleText-g__fz--40 {
		font-size:40px
	}
	.titleText-g__fz--41 {
		font-size:41px
	}
	.titleText-g__fz--42 {
		font-size:42px
	}
	.titleText-g__fz--43 {
		font-size:43px
	}
	.titleText-g__fz--44 {
		font-size:44px
	}
	.titleText-g__fz--45 {
		font-size:45px
	}
	.titleText-g__fz--46 {
		font-size:46px
	}
	.titleText-g__fz--47 {
		font-size:47px
	}
	.titleText-g__fz--48 {
		font-size:48px
	}
	.titleText-g__fz--49 {
		font-size:49px
	}
	.titleText-g__fz--50 {
		font-size:50px
	}
	.titleText-g__fz--51 {
		font-size:51px
	}
	.titleText-g__fz--52 {
		font-size:52px
	}
	.titleText-g__fz--53 {
		font-size:53px
	}
	.titleText-g__fz--54 {
		font-size:54px
	}
	.titleText-g__fz--55 {
		font-size:55px
	}
	.titleText-g__fz--56 {
		font-size:56px
	}
	.titleText-g__fz--57 {
		font-size:57px
	}
	.titleText-g__fz--58 {
		font-size:58px
	}
	.titleText-g__fz--59 {
		font-size:59px
	}
	.titleText-g__fz--60 {
		font-size:60px
	}
	.titleText-g__fz--61 {
		font-size:61px
	}
	.titleText-g__fz--62 {
		font-size:62px
	}
	.titleText-g__fz--63 {
		font-size:63px
	}
	.titleText-g__fz--64 {
		font-size:64px
	}
	.titleText-g__fz--65 {
		font-size:65px
	}
	.titleText-g__fz--66 {
		font-size:66px
	}
	.titleText-g__fz--67 {
		font-size:67px
	}
	.titleText-g__fz--68 {
		font-size:68px
	}
	.titleText-g__fz--69 {
		font-size:69px
	}
	.titleText-g__fz--70 {
		font-size:70px
	}
	.titleText-g__fz--71 {
		font-size:71px
	}
	.titleText-g__fz--72 {
		font-size:72px
	}
	.titleText-g__fz--73 {
		font-size:73px
	}
	.titleText-g__fz--74 {
		font-size:74px
	}
	.titleText-g__fz--75 {
		font-size:75px
	}
	.titleText-g__fz--76 {
		font-size:76px
	}
	.titleText-g__fz--77 {
		font-size:77px
	}
	.titleText-g__fz--78 {
		font-size:78px
	}
	.titleText-g__fz--79 {
		font-size:79px
	}
	.titleText-g__fz--80 {
		font-size:80px
	}
	.titleText-g__fz--81 {
		font-size:81px
	}
	.titleText-g__fz--82 {
		font-size:82px
	}
	.titleText-g__fz--83 {
		font-size:83px
	}
	.titleText-g__fz--84 {
		font-size:84px
	}
	.titleText-g__fz--85 {
		font-size:85px
	}
	.titleText-g__fz--86 {
		font-size:86px
	}
	.titleText-g__fz--87 {
		font-size:87px
	}
	.titleText-g__fz--88 {
		font-size:88px
	}
	.titleText-g__fz--89 {
		font-size:89px
	}
	.titleText-g__fz--90 {
		font-size:90px
	}
	.titleText-g__fz--91 {
		font-size:91px
	}
	.titleText-g__fz--92 {
		font-size:92px
	}
	.titleText-g__fz--93 {
		font-size:93px
	}
	.titleText-g__fz--94 {
		font-size:94px
	}
	.titleText-g__fz--95 {
		font-size:95px
	}
	.titleText-g__fz--96 {
		font-size:96px
	}
	.titleText-g__fz--97 {
		font-size:97px
	}
	.titleText-g__fz--98 {
		font-size:98px
	}
	.titleText-g__fz--99 {
		font-size:99px
	}
	.titleText-g__fz--100 {
		font-size:100px
	}*/
}
@media (min-width:768px) and (max-width:1024px) {
	/*.titleText-g__fz--1 {
		font-size:15px
	}
	.titleText-g__fz--2 {
		font-size:15px
	}
	.titleText-g__fz--3 {
		font-size:15px
	}
	.titleText-g__fz--4 {
		font-size:15px
	}
	.titleText-g__fz--5 {
		font-size:15px
	}
	.titleText-g__fz--6 {
		font-size:15px
	}
	.titleText-g__fz--7 {
		font-size:15px
	}
	.titleText-g__fz--8 {
		font-size:15px
	}
	.titleText-g__fz--9 {
		font-size:15px
	}
	.titleText-g__fz--10 {
		font-size:15px
	}
	.titleText-g__fz--11 {
		font-size:15px
	}
	.titleText-g__fz--12 {
		font-size:15px
	}
	.titleText-g__fz--13 {
		font-size:15px
	}*/
	.titleText-g__fz--14 {
		font-size:15px
	}
	.titleText-g__fz--15 {
		font-size:15px
	}
	.titleText-g__fz--16 {
		font-size:16px
	}
	.titleText-g__fz--17 {
		font-size:15px
	}
	.titleText-g__fz--18 {
		font-size:15px
	}
	.titleText-g__fz--19 {
		font-size:15px
	}
	.titleText-g__fz--20 {
		font-size:15px
	}
	/*.titleText-g__fz--21 {
		font-size:15px
	}
	.titleText-g__fz--22 {
		font-size:15px
	}
	.titleText-g__fz--23 {
		font-size:15px
	}
	.titleText-g__fz--24 {
		font-size:15px
	}
	.titleText-g__fz--25 {
		font-size:15px
	}
	.titleText-g__fz--26 {
		font-size:15px
	}
	.titleText-g__fz--27 {
		font-size:15px
	}
	.titleText-g__fz--28 {
		font-size:15px
	}
	.titleText-g__fz--29 {
		font-size:15px
	}
	.titleText-g__fz--30 {
		font-size:15px
	}
	.titleText-g__fz--31 {
		font-size:15px
	}
	.titleText-g__fz--32 {
		font-size:15px
	}
	.titleText-g__fz--33 {
		font-size:15px
	}
	.titleText-g__fz--34 {
		font-size:15px
	}
	.titleText-g__fz--35 {
		font-size:15px
	}
	.titleText-g__fz--36 {
		font-size:15px
	}
	.titleText-g__fz--37 {
		font-size:15px
	}
	.titleText-g__fz--38 {
		font-size:15px
	}
	.titleText-g__fz--39 {
		font-size:15px
	}
	.titleText-g__fz--40 {
		font-size:15px
	}
	.titleText-g__fz--41 {
		font-size:15px
	}
	.titleText-g__fz--42 {
		font-size:15px
	}
	.titleText-g__fz--43 {
		font-size:15px
	}
	.titleText-g__fz--44 {
		font-size:15px
	}
	.titleText-g__fz--45 {
		font-size:15px
	}
	.titleText-g__fz--46 {
		font-size:15px
	}
	.titleText-g__fz--47 {
		font-size:15px
	}
	.titleText-g__fz--48 {
		font-size:15px
	}
	.titleText-g__fz--49 {
		font-size:15px
	}
	.titleText-g__fz--50 {
		font-size:15px
	}
	.titleText-g__fz--51 {
		font-size:15px
	}
	.titleText-g__fz--52 {
		font-size:15px
	}
	.titleText-g__fz--53 {
		font-size:15px
	}
	.titleText-g__fz--54 {
		font-size:15px
	}
	.titleText-g__fz--55 {
		font-size:15px
	}
	.titleText-g__fz--56 {
		font-size:15px
	}
	.titleText-g__fz--57 {
		font-size:15px
	}
	.titleText-g__fz--58 {
		font-size:15px
	}
	.titleText-g__fz--59 {
		font-size:15px
	}
	.titleText-g__fz--60 {
		font-size:15px
	}
	.titleText-g__fz--61 {
		font-size:15px
	}
	.titleText-g__fz--62 {
		font-size:15px
	}
	.titleText-g__fz--63 {
		font-size:15px
	}
	.titleText-g__fz--64 {
		font-size:15px
	}
	.titleText-g__fz--65 {
		font-size:15px
	}
	.titleText-g__fz--66 {
		font-size:15px
	}
	.titleText-g__fz--67 {
		font-size:15px
	}
	.titleText-g__fz--68 {
		font-size:15px
	}
	.titleText-g__fz--69 {
		font-size:15px
	}
	.titleText-g__fz--70 {
		font-size:15px
	}
	.titleText-g__fz--71 {
		font-size:15px
	}
	.titleText-g__fz--72 {
		font-size:15px
	}
	.titleText-g__fz--73 {
		font-size:15px
	}
	.titleText-g__fz--74 {
		font-size:15px
	}
	.titleText-g__fz--75 {
		font-size:15px
	}
	.titleText-g__fz--76 {
		font-size:15px
	}
	.titleText-g__fz--77 {
		font-size:15px
	}
	.titleText-g__fz--78 {
		font-size:15px
	}
	.titleText-g__fz--79 {
		font-size:15px
	}
	.titleText-g__fz--80 {
		font-size:15px
	}
	.titleText-g__fz--81 {
		font-size:15px
	}
	.titleText-g__fz--82 {
		font-size:15px
	}
	.titleText-g__fz--83 {
		font-size:15px
	}
	.titleText-g__fz--84 {
		font-size:15px
	}
	.titleText-g__fz--85 {
		font-size:15px
	}
	.titleText-g__fz--86 {
		font-size:15px
	}
	.titleText-g__fz--87 {
		font-size:15px
	}
	.titleText-g__fz--88 {
		font-size:15px
	}
	.titleText-g__fz--89 {
		font-size:15px
	}
	.titleText-g__fz--90 {
		font-size:15px
	}
	.titleText-g__fz--91 {
		font-size:15px
	}
	.titleText-g__fz--92 {
		font-size:15px
	}
	.titleText-g__fz--93 {
		font-size:15px
	}
	.titleText-g__fz--94 {
		font-size:15px
	}
	.titleText-g__fz--95 {
		font-size:15px
	}
	.titleText-g__fz--96 {
		font-size:15px
	}
	.titleText-g__fz--97 {
		font-size:15px
	}
	.titleText-g__fz--98 {
		font-size:15px
	}
	.titleText-g__fz--99 {
		font-size:15px
	}
	.titleText-g__fz--100 {
		font-size:15px
	}*/
}
@media (min-width:320px) and (max-width:1024px) {
	/*.titleText-g__fz--1 {
		font-size:15px
	}
	.titleText-g__fz--2 {
		font-size:15px
	}
	.titleText-g__fz--3 {
		font-size:15px
	}
	.titleText-g__fz--4 {
		font-size:15px
	}
	.titleText-g__fz--5 {
		font-size:15px
	}
	.titleText-g__fz--6 {
		font-size:15px
	}
	.titleText-g__fz--7 {
		font-size:15px
	}
	.titleText-g__fz--8 {
		font-size:15px
	}
	.titleText-g__fz--9 {
		font-size:15px
	}
	.titleText-g__fz--10 {
		font-size:15px
	}
	.titleText-g__fz--11 {
		font-size:15px
	}
	.titleText-g__fz--12 {
		font-size:15px
	}
	.titleText-g__fz--13 {
		font-size:15px
	}*/
	.titleText-g__fz--14 {
		font-size:15px
	}
	.titleText-g__fz--15 {
		font-size:15px
	}
	.titleText-g__fz--16 {
		font-size:15px
	}
	.titleText-g__fz--17 {
		font-size:15px
	}
	.titleText-g__fz--18 {
		font-size:15px
	}
	.titleText-g__fz--19 {
		font-size:15px
	}
	.titleText-g__fz--20 {
		font-size:15px
	}
	/*.titleText-g__fz--21 {
		font-size:15px
	}
	.titleText-g__fz--22 {
		font-size:15px
	}
	.titleText-g__fz--23 {
		font-size:15px
	}
	.titleText-g__fz--24 {
		font-size:15px
	}
	.titleText-g__fz--25 {
		font-size:15px
	}
	.titleText-g__fz--26 {
		font-size:15px
	}
	.titleText-g__fz--27 {
		font-size:15px
	}
	.titleText-g__fz--28 {
		font-size:15px
	}
	.titleText-g__fz--29 {
		font-size:15px
	}
	.titleText-g__fz--30 {
		font-size:15px
	}
	.titleText-g__fz--31 {
		font-size:15px
	}
	.titleText-g__fz--32 {
		font-size:15px
	}
	.titleText-g__fz--33 {
		font-size:15px
	}
	.titleText-g__fz--34 {
		font-size:15px
	}
	.titleText-g__fz--35 {
		font-size:15px
	}
	.titleText-g__fz--36 {
		font-size:15px
	}
	.titleText-g__fz--37 {
		font-size:15px
	}
	.titleText-g__fz--38 {
		font-size:15px
	}
	.titleText-g__fz--39 {
		font-size:15px
	}
	.titleText-g__fz--40 {
		font-size:15px
	}
	.titleText-g__fz--41 {
		font-size:15px
	}
	.titleText-g__fz--42 {
		font-size:15px
	}
	.titleText-g__fz--43 {
		font-size:15px
	}
	.titleText-g__fz--44 {
		font-size:15px
	}
	.titleText-g__fz--45 {
		font-size:15px
	}
	.titleText-g__fz--46 {
		font-size:15px
	}
	.titleText-g__fz--47 {
		font-size:15px
	}
	.titleText-g__fz--48 {
		font-size:15px
	}
	.titleText-g__fz--49 {
		font-size:15px
	}
	.titleText-g__fz--50 {
		font-size:15px
	}
	.titleText-g__fz--51 {
		font-size:15px
	}
	.titleText-g__fz--52 {
		font-size:15px
	}
	.titleText-g__fz--53 {
		font-size:15px
	}
	.titleText-g__fz--54 {
		font-size:15px
	}
	.titleText-g__fz--55 {
		font-size:15px
	}
	.titleText-g__fz--56 {
		font-size:15px
	}
	.titleText-g__fz--57 {
		font-size:15px
	}
	.titleText-g__fz--58 {
		font-size:15px
	}
	.titleText-g__fz--59 {
		font-size:15px
	}
	.titleText-g__fz--60 {
		font-size:15px
	}
	.titleText-g__fz--61 {
		font-size:15px
	}
	.titleText-g__fz--62 {
		font-size:15px
	}
	.titleText-g__fz--63 {
		font-size:15px
	}
	.titleText-g__fz--64 {
		font-size:15px
	}
	.titleText-g__fz--65 {
		font-size:15px
	}
	.titleText-g__fz--66 {
		font-size:15px
	}
	.titleText-g__fz--67 {
		font-size:15px
	}
	.titleText-g__fz--68 {
		font-size:15px
	}
	.titleText-g__fz--69 {
		font-size:15px
	}
	.titleText-g__fz--70 {
		font-size:15px
	}
	.titleText-g__fz--71 {
		font-size:15px
	}
	.titleText-g__fz--72 {
		font-size:15px
	}
	.titleText-g__fz--73 {
		font-size:15px
	}
	.titleText-g__fz--74 {
		font-size:15px
	}
	.titleText-g__fz--75 {
		font-size:15px
	}
	.titleText-g__fz--76 {
		font-size:15px
	}
	.titleText-g__fz--77 {
		font-size:15px
	}
	.titleText-g__fz--78 {
		font-size:15px
	}
	.titleText-g__fz--79 {
		font-size:15px
	}
	.titleText-g__fz--80 {
		font-size:15px
	}
	.titleText-g__fz--81 {
		font-size:15px
	}
	.titleText-g__fz--82 {
		font-size:15px
	}
	.titleText-g__fz--83 {
		font-size:15px
	}
	.titleText-g__fz--84 {
		font-size:15px
	}
	.titleText-g__fz--85 {
		font-size:15px
	}
	.titleText-g__fz--86 {
		font-size:15px
	}
	.titleText-g__fz--87 {
		font-size:15px
	}
	.titleText-g__fz--88 {
		font-size:15px
	}
	.titleText-g__fz--89 {
		font-size:15px
	}
	.titleText-g__fz--90 {
		font-size:15px
	}
	.titleText-g__fz--91 {
		font-size:15px
	}
	.titleText-g__fz--92 {
		font-size:15px
	}
	.titleText-g__fz--93 {
		font-size:15px
	}
	.titleText-g__fz--94 {
		font-size:15px
	}
	.titleText-g__fz--95 {
		font-size:15px
	}
	.titleText-g__fz--96 {
		font-size:15px
	}
	.titleText-g__fz--97 {
		font-size:15px
	}
	.titleText-g__fz--98 {
		font-size:15px
	}
	.titleText-g__fz--99 {
		font-size:15px
	}
	.titleText-g__fz--100 {
		font-size:15px
	}*/
}
.title-g__title h1, .title-g__title h2, .title-g__title h3, .title-g__title h4, .title-g__title h5, .title-g__title h6 {
	letter-spacing:3.6px;
	margin:0;
	position:relative;
	line-height:1.4
}
.title-g__icon {
	margin:0
}
.title-g__icon img {
	max-width:100%;
	height:auto;
	display:block
}
.title-g--inline {
	display:inline-block
}
.title-g--block {
	display:block
}
.custom-underline u {
    text-decoration:none;
}
.title-g--border, .custom-underline u {
	position:relative;
	font-style:normal;
	padding-bottom:7px
}
.title-g--border:before, .custom-underline u:before {
	content:"";
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	height:4px;
	width:100%;
	position:absolute;
	bottom:0;
	left:0
}
@media (max-width:768px) {
    .custom-underline u:before {
        display:none;
    }
    .home-contact .title-g--block::before {
        bottom:-6px;
    }
}
.title-g__text {
	margin-top:20px;
	line-height:1.1
}
.title-g__buttons {
	margin-top:26px
}
@media (min-width:320px) and (max-width:1024px) {
	.title-g__title h1, .title-g__title h2, .title-g__title h3, .title-g__title h4, .title-g__title h5, .title-g__title h6 {
		letter-spacing:1.73px
	}
}
.item-g__one {
	background:#fff
}
.item-g__one:hover .item-g__oneImage > span {
	opacity:1;
	visibility:visible
}
.item-g__one:hover .item-g__oneImage:before {
	opacity:1;
	visibility:visible
}
.item-g__oneImage {
	height:260px;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	margin:0;
	background-color:gray;
	position:relative;
	overflow:hidden
}
.item-g__oneImage:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, 0.2);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:0;
	visibility:hidden;
	z-index:1
}
.item-g__oneImage > span {
	position:absolute;
	top:50%;
	left:50%;
	display:block;
	-webkit-transform:translate(-50%, -50%);
	-ms-transform:translate(-50%, -50%);
	transform:translate(-50%, -50%);
	color:#fff;
	font-size:45px;
	opacity:0;
	visibility:hidden;
	z-index:3
}
.item-g__oneInfo {
	padding:24px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.item-g__oneText1 > h3 {
	color:#E3051B;
	font-size:15px;
	font-family:"TTNorms-Regular", sans-serif;
	margin:0
}
.item-g__oneText2 {
	margin-top:12px;
	color:#000;
	font-size:15px;
	font-family:"TTNorms-Regular", sans-serif
}
.item-g__oneText2 p {
	margin:0
}
.item-g__oneText3 {
	margin-top:15px;
	color:#000;
	font-size:15px;
	font-family:"TTNorms-Regular", sans-serif
}
@media (max-width:1024px) {
	.item-g__oneText2 {
		font-size:12px
	}
	.item-g__oneText1 > h3 {
		font-size:12px
	}
	.item-g__oneText3 {
		font-size:12px
	}
}
.item-g__Two {
	background:#fff
}
.item-g__Two:hover .item-g__TwoImage:before {
	opacity:1;
	visibility:visible
}
.item-g__TwoImage {
	height:340px;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	margin:0;
	background-color:gray;
	position:relative;
	overflow:hidden;
	display:block
}
.item-g__TwoImage:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, 0.2);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:0;
	visibility:hidden
}
.item-g__TwoInfo {
	display:block;
	padding:45px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.item-g__TwoText1 {
	display:block;
	margin:0
}
.item-g__TwoText1 > h4 {
	color:#E3051B;
	font-size:36px;
	font-family:"TTNorms-Light", sans-serif;
	margin:0;
	line-height:1.2;
	letter-spacing:2.7px
}
.item-g__TwoText2 {
	padding-top:25px;
	display:block;
	margin:0
}
.item-g__TwoText2 > h3 {
	color:#000;
	font-size:23px;
	font-family:"TTNorms-Light", sans-serif;
	margin:0;
	line-height:1.3;
	letter-spacing:1.65px
}
.item-g__TwoText3 {
	margin:0;
	padding-top:15px;
	padding-bottom:45px;
	color:#000;
	font-size:15px;
	font-family:"TTNorms-Regular", sans-serif;
	max-width:60%;
	display:block
}
.item-g__TwoButton {
	text-align:center;
	padding-top:45px
}
.item-g__TwoButton .button-g {
	position:absolute;
	bottom:45px;
	left:50%;
	-webkit-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
	margin:auto
}
@media (min-width:1025px) {
	.item-g__TwoButton .button-g:hover {
		-webkit-transform:scale(1.06) translateX(-50%);
		-ms-transform:scale(1.06) translateX(-50%);
		transform:scale(1.06) translateX(-50%);
		-webkit-box-shadow:0px 0px 20px rgba(0, 0, 0, 0.3);
		box-shadow:0px 0px 20px rgba(0, 0, 0, 0.3)
	}
}
@media (max-width:1024px) {
	.item-g__TwoText3 {
		width:100%
	}
}
@media (max-width:1024px) {
	.item-g__TwoInfo {
		padding:35px
	}
	.item-g__TwoText1 > h4 {
		font-size:20px
	}
	.item-g__TwoText2 > h3 {
		font-size:14px
	}
	.item-g__TwoText3 {
		width:100%;
		font-size:14px;
		padding-bottom:20px
	}
}
.item-g4__item {
	cursor:pointer
}
.item-g4__item:hover .item-g4__image:before {
	opacity:1;
	visibility:visible
}
.item-g4__item:hover .item-g4__img img {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.item-g4__image {
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	height:415px;
	margin:0;
	position:relative
}
.item-g4__image:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, 0.2);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:0;
	visibility:hidden
}
.item-g4__image img {
	max-width:100%;
	height:auto;
	display:block
}
.item-g4__img {
	margin:0;
	overflow:hidden
}
.item-g4__img img {
	width:100%;
	height:auto;
	display:block;
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out
}
.item-g4__info {
	padding:40px 30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	background:#F1F1F1
}
.item-g4__title h3 {
	font-size:30px;
	font-family:"TTNorms-Light", sans-serif;
	color:#000;
	margin:0;
	line-height:1.3;
	letter-spacing:1.5px
}
.item-g4__text {
	margin-top:30px
}
.item-g4__text span {
	font-family:"TTNorms-Light", sans-serif;
	font-size:15px;
	color:#000
}
.item-g4__text p {
	margin:0;
	font-family:"TTNorms-Light", sans-serif;
	font-size:15px;
	color:#E3051B
}
@media (max-width:1024px) {
	.item-g4__image {
		height:200px
	}
	.item-g4__info {
		padding:35px 30px
	}
	.item-g4__title h3 {
		font-size:16px
	}
	.item-g4__text {
		margin-top:20px
	}
	.item-g4__text span {
		font-size:14px
	}
	.item-g4__text p {
		font-size:14px
	}
}
@media (max-width:1024px) {
	.item-g4__image {
		height:150px
	}
	.item-g4__info {
		padding:35px 30px
	}
	.item-g4__text {
		margin-top:15px
	}
	.item-g4__text span {
		font-size:14px
	}
	.item-g4__text p {
		font-size:14px
	}
}
.miga-g > ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.miga-g > ul > li {
	position:relative
}
.miga-g > ul > li:after {
	content:"";
	display:inline-block;
	width:7px;
	height:13px;
	background:url("../img/icons/arrow-right.svg");
	background-position:0 0;
	background-repeat:no-repeat;
	background-size:100%;
	margin:0 15px
}
.miga-g > ul > li:last-child:after {
	display:none
}
.miga-g > ul > li > a {
	font-size:18px;
	color:#3C3C3B;
	text-transform:uppercase;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	letter-spacing:1.25px
}
.miga-g > ul > li > a:hover {
	color:#E3051B
}
.miga-g > ul > li.current-menu-item > a {
	color:#E3051B
}
.arrows-g {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
/*.arrows-g__element {
	display:block;
	background:none;
	border-radius:0;
	border:none;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	margin:0;
	padding:0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	height:24px;
	line-height:24px;
	width:24px;
	text-align:center;
	border-radius:100%;
	font-size:9px;
	border:1px solid red;
	color:red;
	cursor:pointer
}
.arrows-g__element:hover {
	background:red;
	color:#fff
}*/
.arrow-g__button {
	position:absolute;
	border:none;
	padding:0;
	margin:0;
	border-radius:0;
	border:none;
	top:50%;
	margin-top:-25px;
	height:49px;
	width:17px;
	border-radius:100%;
	text-align:center;
	pointer-events:auto;
	font-size:0;
	z-index:10;
	cursor:pointer;
	background:url("../img/icons/iconflecha-02.svg");
	background-size:100%;
	background-position:0 0;
	background-repeat:no-repeat;
	opacity:0.5;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.arrow-g__button:hover {
	opacity:1
}
.arrow-g__buttonPrev {
	left:0
}
.arrow-g__buttonNext {
	right:0;
	background:url("../img/icons/iconflecha-03.svg")
}
@media (max-width:1024px) {
	.arrow-g__button {
		width:10px;
		height:30px
	}
}
.swiper-button-disabled {
	opacity:0;
	visibility:hidden
}
/*
.acordionContent--js {
	max-height:0;
	overflow:hidden;
	-webkit-transition:max-height 0.2s ease-out;
	-o-transition:max-height 0.2s ease-out;
	transition:max-height 0.2s ease-out
}*/
.main-banner {
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	height:790px;
	background-color:#E4E4E4;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.main-banner--left .main-banner__info {
	margin-left:0;
	margin-right:auto
}
.main-banner--right .main-banner__info {
	margin-left:auto;
	margin-right:0
}
.main-banner__info {
	width:60%
}
.banner-g__inside {
	height:100%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.banner-g__info .title-g__title h1, .banner-g__info .title-g__title h2, .banner-g__info .title-g__title h3, .banner-g__info .title-g__title h4, .banner-g__info .title-g__title h5 {
	letter-spacing:3.6px
}
.banner-g__info .title-g__text {
	line-height:1.5
}
.banner-g__image {
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat
}
.slider-principal__wrap {
	position:relative
}
.slider-principal {
	position:relative;
	height:780px;
	overflow:hidden
}
.slider-principal__item {
	position:relative;
	height:780px;
	overflow:hidden
}
.slider-principal__bg {
	position:relative;
	height:780px;
	width:100%;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat
}
.slider-principal__container {
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	margin:auto;
	height:780px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:100%;
	z-index:10;
	padding-top:30px;
	box-sizing:border-box
}
.slider-principal__info {
	width:41%;
	margin-right:0;
	margin-left:auto;
	padding-bottom:20px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.slider-principal__infoTitle h1 {
	font-size:72px;
	letter-spacing:5.4px;
	font-family:"TTNorms-Light", sans-serif
}
.slider-principal__infoTitle h1 strong {
	font-weight:normal
}
.slider-principal__infoTitle h1 u {
    font-style:normal;
    position:relative;
    display:inline-block;
    padding-bottom:7px;
    text-decoration:none;
}
.slider-principal__infoTitle h1 u::before {
    content:"";
    background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
    background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
    background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
    height:7px;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
}
.slider-principal__infoText {
	margin-top:40px;
	font-size:20px;
}
.slider-principal__infoButton {
	margin-top:40px
}
.slider-principal__infoButton .button-g {
	border-radius:10px
}
.slider-principal__infoButton .button-g__text {
	height:64px;
	line-height:64px;
	border-radius:10px !important
}
.slider-principal__arrows {
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:100%;
	height:100%;
	max-width:100%;
	margin:auto;
	pointer-events:none;
	z-index:3;
	font-size:0
}
.slider-principal__prev, .slider-principal__next {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	background-repeat:no-repeat;
	background-position:0 0;
	background-size:100%;
	cursor:pointer;
	text-align:center;
	display:block;
	pointer-events:auto;
	opacity:.5;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.slider-principal__prev:hover, .slider-principal__next:hover {
	opacity:1
}
.slider-principal__prev {
	left:20px
}
.slider-principal__next {
	right:20px
}
.slider-principal__prev {
	background:url("../img/icons/arrowslide-02.svg");
	width:48px;
	height:79px
}
.slider-principal__next {
	background:url("../img/icons/arrowslide-03.svg");
	width:48px;
	height:79px
}
.slider-principal__pagination {
	margin-top:50px
}
.slider-principal__pagination.swiper-pagination {
	bottom:40px !important;
	margin:0
}
.slider-principal__pagination .swiper-pagination-bullet {
	width:85px;
	height:6px;
	border-radius:0;
	background:rgba(255, 255, 255, 0.5);
	margin:0 6px !important;
	text-align:center
}
.slider-principal__pagination .swiper-pagination-bullet:last-child {
	margin-right:0
}
.slider-principal__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background:#fff
}
@media (max-width:1400px) {
	.slider-principal__info {
		width:50%
	}
	.slider-principal {
		height:700px
	}
	.slider-principal__item {
		height:700px
	}
	.slider-principal__container {
		height:700px;
		padding-top:0
	}
	.slider-principal__bg {
		height:700px
	}
	.slider-principal__infoTitle h1 {
		font-size:50px
	}
}

@media (max-width:1024px) {
	.slider-principal__container {
		padding-top:0
	}
	/*.slider-principal__image {
		left:0;
		width:52%
	}
	.slider-principal__ancla {
		bottom:-25px
	}*/
}

@media (max-width:1024px) {
	.slider-principal {
		height:620px
	}
	.slider-principal__item {
		height:620px
	}
	.slider-principal__container {
		height:620px
	}
	/*.slider-principal__image {
		display:none
	}*/
	.slider-principal__bg {
		height:620px
	}
	.slider-principal__info {
		width:100%;
		height:100%;
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-ms-flex-direction:column;
		flex-direction:column;
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between;
		padding:40px 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.slider-principal__prev {
		left:10px;
		width:16px;
		height:43px;
		background:url("../img/icons/arrowslideresp-05.svg")
	}
	.slider-principal__next {
		right:10px;
		width:16px;
		height:43px;
		background:url("../img/icons/arrowslideresp-04.svg")
	}
	.slider-principal__pagination {
		display:none
	}
	.slider-principal__infoTitle h1 {
		font-size:40px;
	}
	.slider-principal__infoText {
		font-size:16px
	}
	.slider-principal__infoButton {
		text-align:center
	}
	.slider-principal__infoButton .button-g__text {
		height:50px;
		line-height:50px;
		font-size:17px !important;
	}
}
@media (max-width:767px) {
    .slider-principal__infoTitle h1 {
        font-size:28px
	}
	.slider-principal__info {
	    width: 100%;
	    height: 100%;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	    padding: 40px 0;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	.slider-principal__infoText {
	    font-size: 14px
	}
	.slider-principal__infoButton .button-g__text {
	    height: 40px;
	    line-height: 40px
	}
	.slider-principal__infoButton .button-g__text {
	    height: 50px;
	    line-height: 50px;
	    font-size: 10px !important;
	}
	.slider-principal__prev {
	    left: 10px;
	    width: 8px;
	    height: 21px;
	    background: url("../img/icons/arrowslideresp-05.svg")
	}
	.slider-principal__next {
	    right: 10px;
	    width: 8px;
	    height: 21px;
	    background: url("../img/icons/arrowslideresp-04.svg")
	}
}
.wpcf7-form-control-wrap {
	display:block
}
.wpcf7 .wpcf7-response-output {
	margin:15px 0;
	padding:10px;
	font-size:14px;
	border-radius:3px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#E3051B
}
.left {
	text-align:left
}
.right {
	text-align:right
}
.center {
	text-align:center
}
.alignleft {
	display:inline;
	float:left;
	margin-right:1.5em
}
.alignright {
	display:inline;
	float:right;
	margin-left:1.5em
}
.aligncenter {
	clear:both;
	display:block;
	margin-left:auto;
	margin-right:auto
}
@media (max-width:1024px) {
	.alignleft {
		display:block;
		margin:15px
	}
	.alignright {
		display:block;
		margin:15px
	}
}
.home-zigzag .zigzap-g__title .title-g__title h1, .home-zigzag .zigzap-g__title .title-g__title h2, .home-zigzag .zigzap-g__title .title-g__title h3, .home-zigzag .zigzap-g__title .title-g__title h4, .home-zigzag .zigzap-g__title .title-g__title h5 {
	letter-spacing:1.63px
}
.home-zigzag .zigzap-g__title .title-g__text {
	line-height:1.6;
	margin-top:40px
}
.home-zigzag .zigzap-g__title .title-g__buttons {
	margin-top:70px
}
.home-zigzag .zigzap-g__item:first-child .zigzap-g__info {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%)
}
@media (max-width:1024px) {
	.home-zigzag .zigzap-g__title .title-g__text {
		margin-top:40px
	}
	.home-zigzag .zigzap-g__title .title-g__buttons {
		margin-top:40px;
		text-align:center
	}
	.home--categories .categories-block__title {
	    padding-bottom: 90px;
	    max-width: 880px;
		width: 100%;
		text-align:center;
		margin:0 auto;
	}
}
.home--categories {
	padding-top:90px;
	padding-bottom:75px;
	background:#F1F1F1
}
.home--categories .categories-block__title {
	padding-bottom:90px;
	max-width:880px;
	width:100%;
}
.home--categories .categories-block__title .title-g__title h1, .home--categories .categories-block__title .title-g__title h2, .home--categories .categories-block__title .title-g__title h3, .home--categories .categories-block__title .title-g__title h4, .home--categories .categories-block__title .title-g__title h5 {
	letter-spacing:3.6px
}
@media(max-width:767px){
	.home--categories .categories-block__title {
	    padding-bottom: 90px;
	    max-width: 880px;
	    width: 100%;
	    margin-left:0;
	    margin-right:auto
	}
}
@media (max-width:1200px) {
	.home--categories .categories-block__title {
		padding-bottom:60px
	}
}
@media (max-width:1024px) {
	.categories-g__item {
		width:50%
	}
}
@media (max-width:1024px) {
	.home--categories .categories-block__title {
		padding-left:30px;
		padding-right:30px;
		padding-bottom:40px
	}
}
.home--catalogo {
	background:#F1F1F1;
	padding-bottom:75px
}
.home--guia {
	padding-bottom:75px;
	background:#F1F1F1
}
.home-clients {
	padding-bottom:70px;
	background:#F1F1F1
}
.home-clients .arrow-g__buttonPrev {
	left:-25px
}
.home-clients .arrow-g__buttonNext {
	right:-25px
}
.home-clients--slider.active-slidearrows .arrow-g {
	opacity:0;
	visibility:hidden
}
/*
.home-recursos--slider.active-slidearrows .arrow-g {
	opacity:0;
	visibility:hidden
}
*/
.home-clients__title {
	padding-bottom:60px
}
.home-clients__slider {
	overflow:hidden
}
.home-clients__button {
	text-align:center;
	margin-top:70px
}
@media (max-width:1600px) {
	.home-clients .arrow-g__buttonPrev {
		left:10px
	}
	.home-clients .arrow-g__buttonNext {
		right:10px
	}
}
@media (max-width:1024px) {
	.home-clients__title {
		padding-bottom:40px;
		text-align:center
	}
	.home-clients__button {
		margin-top:40px
	}
	.home-clients .arrow-g__buttonPrev {
		left:15px
	}
	.home-clients .arrow-g__buttonNext {
		right:15px
	}
}
@media (max-width:479px) {
	.home-clients__container {
		padding:0 30px !important
	}
	.home-clients .arrow-g__buttonPrev {
		left:10px
	}
	.home-clients .arrow-g__buttonNext {
		right:10px
	}
}
.home--asesoria {
	background:#F1F1F1
}
/*
.home-recursos {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:70px 0
}
.home-recursos .arrow-g__buttonPrev {
	left:-25px
}
.home-recursos .arrow-g__buttonNext {
	right:-25px
}
.home-recursos .arrow-g__buttonNext {
	background:url("../img/icons/arrowx-03.svg")
}
.home-recursos .arrow-g__buttonPrev {
	background:url("../img/icons/arrowx-02.svg")
}
.home-recursos__title {
	margin-bottom:50px
}
.home-recursos__slider {
	overflow:hidden
}
@media (max-width:1600px) {
	.home-recursos .arrow-g__buttonPrev {
		left:10px
	}
	.home-recursos .arrow-g__buttonNext {
		right:10px
	}
}
@media (max-width:1024px) {
	.home-recursos .arrow-g__buttonPrev {
		left:15px
	}
	.home-recursos .arrow-g__buttonNext {
		right:15px
	}
}
@media (max-width:479px) {
	.home-recursos__container {
		padding:0 30px !important
	}
	.home-recursos .arrow-g__buttonPrev {
		left:10px
	}
	.home-recursos .arrow-g__buttonNext {
		right:10px
	}
}
.home-soporte {
	padding-top:70px;
	padding-bottom:80px
}
.home-soporte__title {
	margin-bottom:60px
}
.home-soporte__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.home-soporte__item {
	margin:0;
	padding:0 5%;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-right:1px solid rgba(0, 0, 0, 0.3)
}
.home-soporte__item:last-child {
	border-right:none;
	padding-right:0
}
.home-soporte__item:first-child {
	padding-left:0
}
.home-soporte__item__title {
	max-width:385px;
	width:100%;
	margin:auto
}
.home-soporte__item__title .title-g__icon {
	width:97px;
	margin-bottom:20px
}
.home-soporte__item__title .title-g__icon img {
	margin-left:0;
	margin-right:auto;
	max-width:100%;
	height:auto
}
.home-soporte__item__title .title-g__title {
	margin-top:2px
}
.home-soporte__item__title .title-g__text {
	margin-top:25px;
	line-height:1.4
}
.home-soporte__item__title .title-g__buttons {
	margin-top:25px
}
@media (max-width:1024px) {
	.home-soporte {
		padding-top:60px;
		padding-bottom:60px
	}
}
@media (max-width:1024px) {
	.home-soporte__item {
		padding:0 3%
	}
}
@media (max-width:1024px) {
	.home-soporte {
		padding-top:0;
		padding-bottom:0
	}
	.home-soporte__title {
		display:none
	}
	.home-soporte__items {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.home-soporte__item {
		width:100%;
		padding:40px 0;
		border-right:none;
		text-align:center;
		border-bottom:1px solid rgba(0, 0, 0, 0.3)
	}
	.home-soporte__item:last-child {
		border-bottom:none
	}
	.home-soporte__item__title .title-g__icon {
		margin:auto;
		margin-bottom:30px
	}
	.home-soporte__item__title .title-g__title {
		text-align:center
	}
	.home-soporte__item__title .title-g__title br {
		display:none
	}
	.home-soporte__item__title .title-g__text {
		display:none
	}
}
*/
.home-contact {
	padding-top:80px;
	padding-bottom:80px;
	background:#F1F1F1
}
.home-contact__title {
	max-width:900px;
	width:100%;
	margin:auto;
	text-align:center;
	margin-bottom:45px
}
.home-contact__title .title-g__title h1, .home-contact__title .title-g__title h2, .home-contact__title .title-g__title h3, .home-contact__title .title-g__title h4, .home-contact__title .title-g__title h5 {
	letter-spacing:1.63px;
	line-height:1.2
}
.home-contact__form {
	max-width:880px;
	width:100%;
	margin:auto
}
.home-contact__form .form-block__button {
	text-align:center;
	margin-top:40px
}
@media (max-width:767px) {
	.home-contact {
		height:450px;
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center
	}
	.home-contact__form {
		display:none
	}
	.home-contact__title .title-g__title h1, .home-contact__title .title-g__title h2, .home-contact__title .title-g__title h3, .home-contact__title .title-g__title h4, .home-contact__title .title-g__title h5 {
		font-size:28px !important;
		letter-spacing:2.1px;
		line-height:1.3
	}
}
@media (max-width:1024px) {
	.zigzap-g__title {
	    text-align: center;
	    margin: 0 auto !important;
	}
}
.form-block {
	margin:0 !important
}
.politicas-block {
	padding-top:70px;
	padding-bottom:100px
}
.politicas-block__content {
	font-size:20px;
	margin-top:60px;
	padding-top:25px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#000;
	border-top:1px solid #B3B3B3
}
@media (max-width:1024px) {
	.politicas-block {
		padding-top:40px;
		padding-bottom:50px
	}
	.politicas-block__content {
		font-size:16px;
		margin-top:40px;
		padding-top:20px
	}
}
/*
.filter--casos .item-g__oneInfo {
	background:#F2F2F2
}*/
/*
.banner-g--recursos .banner-g__image {
	height:780px
}
.banner-g--recursos .banner-g__info {
	width:40%;
	margin-left:0;
	margin-right:auto
}
.banner-g--recursos .title-g .title-g__title h1, .banner-g--recursos .title-g .title-g__title h2, .banner-g--recursos .title-g .title-g__title h3, .banner-g--recursos .title-g .title-g__title h4, .banner-g--recursos .title-g .title-g__title h5 {
	letter-spacing:5.4px
}
.banner-g--recursos .title-g .title-g__text {
	margin-top:35px;
	line-height:1.5
}
@media (max-width:1200px) {
	.banner-g--recursos .banner-g__info {
		width:50%
	}
	.banner-g--recursos .banner-g__image {
		height:610px;
		padding:70px 0;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--recursos .banner-g__info {
		width:100%
	}
	.banner-g--recursos .banner-g__inside {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
}
*/
.olvidaste--js, .olvidastecursos--js, .olvidasteplataforma--js {
	font-size:17px;
	font-family:"TTNorms-Light", sans-serif;
	color:#E3051B;
	cursor:pointer
}
.zigzap-g--gradient {
	background:-webkit-gradient(linear, left top, right top, from(#e9590c), to(#e2051b));
	background:-o-linear-gradient(left, #e9590c 0%, #e2051b 100%);
	background:linear-gradient(to right, #e9590c 0%, #e2051b 100%)
}
.zigzap-g--white {
	background:#fff
}
.zigzap-g__item {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	height:758px
}
.zigzap-g__item:nth-child(even) {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:reverse;
	-ms-flex-direction:row-reverse;
	flex-direction:row-reverse
}
.zigzap-g__item:nth-child(even) .zigzap-g__info {
	padding:40px 50px 40px 140px
}
.zigzap-g__item:nth-child(even) .zigzap-g__title {
	margin-right:auto;
	margin-left:0
}
.zigzap-g__info {
	width:50%;
	padding:40px 140px 40px 50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.zigzap-g__title {
	max-width:690px;
	width:100%;
	margin-right:0;
	margin-left:auto
}
.zigzap-g__imgvideo {
	width:50%;
	overflow:hidden;
	position:relative
}
.zigzap-g__imgvideo > span {
	color:#fff;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%, -50%);
	-ms-transform:translate(-50%, -50%);
	transform:translate(-50%, -50%);
	font-size:50px;
	display:block;
	pointer-events:none
}
.zigzap-g__imgvideo:hover .zigzap-g__img {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.zigzap-g__imgvideo:hover .zigzap-g__img.video--js {
	opacity:0.8
}
.video--js {
	cursor:pointer
}
.zigzap-g__img {
	display:block;
	background-color:gray;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	height:100%;
	position:relative;
	-webkit-transition:all .4s ease-in-out;
	-o-transition:all .4s ease-in-out;
	transition:all .4s ease-in-out;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1)
}
@media (max-width:1400px) {
	.zigzap-g__info {
		padding:40px 40px 40px 40px
	}
	.zigzap-g__item:nth-child(even) .zigzap-g__info {
		padding:40px 40px 40px 40px
	}
}
@media (max-width:1024px) {
	.zigzap-g__info {
		padding:40px 40px 40px 40px;
		width:100%
	}
	.zigzap-g__item:nth-child(even) .zigzap-g__info {
		padding:40px 40px 40px 40px
	}
	.zigzap-g__item {
		height:auto;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.zigzap-g__imgvideo {
		width:100%
	}
	.zigzap-g__img {
		height:340px
	}
	.zigzap-g__imgvideo > span {
		display:none
	}
}
@media (min-width:768px) and (max-width:1024px) {
	.zigzap-g__item{
		flex-direction:column-reverse;
	}
	.zigzap-g__item:nth-child(2n) {
		flex-direction:column-reverse;
	}
}
@media (max-width:400px) {
	.zigzap-g__info {
		padding:40px 20px 40px 20px;
		width:100%
	}
	.zigzap-g__item:nth-child(even) .zigzap-g__info {
		padding:40px 20px 40px 20px
	}
}
.categories-g__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.categories-g__item {
	width:33.33%;
	height:265px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	padding:0 30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	position:relative;
	overflow:hidden;
	margin:0
}
.categories-g__item:hover .categories-g__bg {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.categories-g__bg {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	-webkit-transition:all .4s ease-in-out;
	-o-transition:all .4s ease-in-out;
	transition:all .4s ease-in-out;
	z-index:1
}
.categories-g__name {
	width:40%;
	margin-right:0;
	margin-left:auto;
	position:relative;
	z-index:3
}
.categories-g__name > h3 {
	color:#fff;
	font-size:23px;
	font-family:"TTNorms-Light", sans-serif;
	margin:0;
	line-height:1.4;
	letter-spacing:1.65px
}
@media (max-width:1200px) {
	.categories-g__name > h3 {
		font-size:20px
	}
}
@media (max-width:1024px) {
	.categories-g__name {
		width:45%
	}
	.categories-g__name > h3 {
		font-size:16px
	}
	.categories-g__item {
		width:100%;
		height:150px
	}
	.categories-block__container {
		padding:0 !important
	}
}
.banner-g {
	position:relative;
	overflow:hidden
}
.banner-g__inside {
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:100%;
	height:100%;
	z-index:10
}
.banner-g__image {
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out
}
.banner-g--guia {
	max-width:1680px;
	width:100%;
	margin:0 auto;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	padding:0 40px
}
.banner-g--guia:hover .banner-g__image {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.banner-g--guia .banner-g__image {
	height:400px
}
.banner-g--guia .banner-g__info {
	max-width:550px;
	width:100%;
	margin-right:auto;
	margin-left:0
}
.banner-g--guia .banner-g__inside {
	max-width:1280px
}
.banner-g--guia .banner-g__container {
	overflow:hidden;
	width:100%;
	padding:0 !important;
	margin:0
}
@media (max-width:1024px) {
	.banner-g--guia {
		padding:0 !important
	}
	.banner-g--guia .banner-g__image {
		height:600px
	}
	.banner-g--guia .banner-g__inside {
		-webkit-box-align:end;
		-ms-flex-align:end;
		align-items:flex-end;
		padding-bottom:45px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--guia .banner-g__info {
		max-width:50%;
		margin:auto 0;
	}
	/*.banner-g--guia .banner-g__info h3 {
		font-size:30px;
	}*/
	.banner-g--guia .banner-g__container {
		padding:0 !important
	}
}
@media (max-width:727px){
	.banner-g--guia .banner-g__info {
	    max-width: 300px;
		margin:0 auto;
		padding-left:0;
	}
	/*.banner-g--guia .banner-g__info h3 {
	    font-size: 23px;
	}*/
}
.banner-g--asesoria .banner-g__image {
	height:645px
}
.banner-g--asesoria .banner-g__info {
	max-width:690px;
	width:100%;
	margin-right:auto;
	margin-left:0
}
.banner-g--asesoria .banner-g__info .title-g__title {
	max-width:450px;
	width:100%;
	margin-left:0;
	margin-right:auto
}
.banner-g--asesoria .banner-g__info .title-g__text {
	line-height:1.5;
	margin-top:25px
}
.banner-g--asesoria .banner-g__info .title-g__buttons {
	margin-top:55px
}
.banner-g--asesoria .banner-g__inside {
	max-width:1680px
}
@media (max-width:1024px) {
	.banner-g--asesoria .banner-g__image {
		height:625px
	}
	.banner-g--asesoria .banner-g__inside {
		-webkit-box-align:end;
		-ms-flex-align:end;
		align-items:flex-end;
		padding-bottom:45px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--asesoria .banner-g__info {
		max-width:55%;
		margin:auto 0
	}
	.banner-g--asesoria .banner-g__info .title-g__title {
		max-width:100%
	}
	.banner-g--asesoria .banner-g__info .title-g__title h3 {
	   font-size:30px;
	}
	.banner-g--asesoria .banner-g__info .title-g__text {
		margin-top:40px
	}
	.banner-g--asesoria .banner-g__info .title-g__buttons {
		margin-top:35px
	}
}
@media (max-width:767px) {
	.banner-g--asesoria .banner-g__info .title-g__title h3 {
	    font-size: 23px;
	}
	.banner-g--asesoria .banner-g__info {
	    max-width: 350px;
	    margin: 0 auto
	}
}
.banner-g--garantia:hover .banner-g__image {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.banner-g--garantia .banner-g__image {
	height:600px
}
.banner-g--garantia .banner-g__info {
	max-width:560px;
	width:100%;
	margin-right:0;
	margin-left:auto
}
.banner-g--garantia .banner-g__info .title-g__text {
	line-height:1.5;
	margin-top:45px
}
.banner-g--garantia .banner-g__info .title-g__buttons {
	margin-top:45px
}
.banner-g--garantia .banner-g__info .title-g__buttons .title-g__button {
	margin:20px 0
}
.banner-g--garantia .banner-g__inside {
	max-width:1680px
}
@media (max-width:1200px) {
	.banner-g--garantia .banner-g__info {
		max-width:500px
	}
}
@media (max-width:1024px) {
	.banner-g--garantia .banner-g__info {
		max-width:400px
	}
	.banner-g--garantia .banner-g__image {
		height:700px
	}
	.banner-g--garantia .banner-g__inside {
		-webkit-box-align:end;
		-ms-flex-align:end;
		align-items:flex-end;
		padding-bottom:45px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--garantia .banner-g__info {
		max-width:320px;
		margin:0 auto
	}
	.banner-g--garantia .banner-g__container {
		padding:0 !important
	}
}
.block-g__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.block-g__title {
	text-align:center;
	margin-bottom:95px
}
.block-g__item {
	width:25%;
	text-align:center;
	padding:0 50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.block-g__image {
	width:57px;
	margin:auto;
	margin-bottom:15px
}
.block-g__image img {
	max-width:100%;
	height:auto;
	display:block
}
.block-g__title2 > h3 {
	font-family:"TTNorms-Regular", sans-serif;
	font-size:20px;
	line-height:1.4;
	color:#000;
	margin:0
}
.block-g__textIn {
	font-family:"TTNorms-Regular", sans-serif;
	font-size:15px;
	color:#000;
	margin-top:20px;
	line-height:1.4
}
@media (max-width:1200px) {
	.block-g__item {
		padding:0 25px
	}
	.block-g__title {
		margin-bottom:60px
	}
}
@media (max-width:1024px) {
	.block-g__title {
		margin-bottom:40px
	}
	.block-g__item {
		width:100%;
		text-align:left;
		padding:0;
		margin-bottom:35px
	}
	.block-g__item:last-child {
		margin-bottom:0
	}
	.block-g__imageText {
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center
	}
	.block-g__acordionContent {
		max-height:0;
		overflow:hidden;
		-webkit-transition:max-height 0.2s ease-out;
		-o-transition:max-height 0.2s ease-out;
		transition:max-height 0.2s ease-out
	}
	.block-g__title2 {
		width:calc(100% - 38px);
		padding-left:12px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.block-g__title2 > h3 {
		font-size:16px;
		letter-spacing:1.2px
	}
	.block-g__textIn {
		font-size:14px
	}
	.block-g__image {
		width:38px;
		margin:0
	}
	.block-g__imageText {
		position:relative;
		padding-right:30px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.block-g__imageText.active > span:before {
		opacity:0;
		visibility:hidden
	}
	.block-g__imageText.active > span:after {
		opacity:1;
		visibility:visible
	}
	.block-g__imageText > span {
		position:absolute;
		top:50%;
		-webkit-transform:translateY(-50%);
		-ms-transform:translateY(-50%);
		transform:translateY(-50%);
		right:0;
		font-size:25px;
		font-family:"TTNorms-Bold", sans-serif;
		color:#E3051B
	}
	.block-g__imageText > span:before, .block-g__imageText > span:after {
		position:relative
	}
	.block-g__imageText > span:before {
		content:"+"
	}
	.block-g__imageText > span:after {
		content:"-";
		position:absolute;
		left:0;
		opacity:0;
		visibility:hidden
	}
}
.block-distribuidor form {
	margin:0 !important
}
.block-distribuidor__container {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.block-distribuidor__item {
	width:50%;
	padding:40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	height:730px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.block-distribuidor__item .title-g__icon {
	width:83px;
	margin:0;
	margin-bottom:20px
}
.block-distribuidor__item .title-g__icon img {
	max-width:100%;
	margin-left:0;
	margin-right:auto;
	height:auto
}
.block-distribuidor__b1 {
	background:#F1F1F1
}
.block-distribuidor__b1 .block-distribuidor__titleb1 {
	max-width:630px;
	width:100%;
	margin:auto
}
.block-distribuidor__b1 .block-distribuidor__titleb1 .title-g__text {
	margin-top:40px
}
.block-distribuidor__forminputs {
	margin-top:15px
}
.block-distribuidor__forminputs .input-g__title {
	font-family:"TTNorms-Bold", sans-serif
}
.block-distribuidor__forminputs > ul > li {
	margin-bottom:15px
}
.block-distribuidor__formbtns {
	margin-top:40px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.block-distribuidor__b2 {
	background:#E4E4E4
}
.block-distribuidor__b2 .block-distribuidor__titleb2 {
	max-width:480px;
	width:100%;
	margin:auto
}
.block-distribuidor__b2 .block-distribuidor__titleb2 .title-g__text {
	margin-top:40px;
	line-height:1.7
}
.block-distribuidor__b2 .block-distribuidor__titleb2 .title-g__buttons {
	margin-top:100px
}
@media (max-width:1200px) {
	.block-distribuidor__formbtns {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center
	}
	.block-distribuidor__formbtn1, .block-distribuidor__formbtn2 {
		margin:0 10px
	}
	.block-distribuidor__formbtn1 {
		margin-bottom:20px
	}
}
@media (max-width:1024px) {
	.block-distribuidor__container {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.block-distribuidor__item {
		width:100%;
		height:auto;
		padding:40px
	}
	.block-distribuidor__b1 .title-g__title {
		margin-bottom:35px
	}
	.block-distribuidor__b1 .title-g__text {
		display:none
	}
	.block-distribuidor__b2 .block-distribuidor__titleb2 .title-g__text {
		margin-top:35px
	}
	.block-distribuidor__b2 .block-distribuidor__titleb2 .title-g__buttons {
		margin-top:35px
	}
	.block-distribuidor__item .title-g__icon {
		width:42px;
		margin-bottom:12px
	}
}
@media (max-width:400px) {
	.block-distribuidor__item {
		padding:40px 20px
	}
}
.filter-block form {
	margin:0
}
.filter-block__miga {
	margin-top:30px
}
.filter-block__miga ul {
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end
}
.filter-block__title {
	margin-top:20px
}
.filter-block__filter {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	padding-top:30px;
	padding-bottom:40px
}
.filter-block__filterSelect {
	width:315px;
	margin-right:30px
}
.filter-block__filterSelect:last-child {
	margin-right:0
}
.filter-block__filter1 {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	width:100%
}
.filter-block__result {
	padding-top:35px;
	padding-bottom:30px;
	border-top:1px solid #B3B3B3
}
.filter-block__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.filter-block__item {
	width:32%;
	margin:0;
	margin-right:2%;
	margin-bottom:2%
}
.filter-block__item:nth-child(3n+3) {
	margin-right:0
}
.filter-block__items3 .filter-block__item {
	width:100%;
	margin-bottom:75px
}
.filter-block__items3 .filter-block__item:last-child {
	margin-bottom:0
}
.filter-block__items-2 .filter-block__item {
	width:23.5%;
	margin:0;
	margin-right:2%;
	margin-bottom:2%
}
.filter-block__items-2 .filter-block__item:nth-child(4n+4) {
	margin-right:0
}
@media (max-width:1024px) {
	.filter-block__item {
		width:48%;
		margin-right:4% !important;
		margin-bottom:4% !important
	}
	.filter-block__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.filter-block__items-2 .filter-block__item {
		width:48%;
		margin-right:4% !important;
		margin-bottom:4% !important
	}
	.filter-block__items-2 .filter-block__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.filter--banners {
		padding-top:40px
	}
	.filter--fotografias {
		padding-top:40px
	}
	.filter-block__filter1 {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.filter-block__filterSelect {
		width:100%
	}
	.filter-block__filterSelect {
		margin-right:0;
		margin-bottom:20px
	}
	.filter-block__filterSelect:last-child {
		margin-bottom:0
	}
	.filter-block__miga {
		display:none
	}
	.filter-block__result .title-g__title h1, .filter-block__result .title-g__title h2, .filter-block__result .title-g__title h3, .filter-block__result .title-g__title h4, .filter-block__result .title-g__title h5, .filter-block__result .title-g__title h6 {
		font-size:14px !important
	}
	.filter-block__title {
		margin-top:40px
	}
}
@media (max-width:480px) {
	.filter-block__item {
		width:100%;
		margin-right:0 !important;
		margin-bottom:40px !important
	}
	.filter-block__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.filter-block__item:last-child {
		margin-bottom:0 !important
	}
	.filter-block__items-2 .filter-block__item {
		width:100%;
		margin-right:0 !important;
		margin-bottom:40px !important
	}
	.filter-block__items-2 .filter-block__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.filter-block__items-2 .filter-block__item:last-child {
		margin-bottom:0 !important
	}
}
/*.acordionContent--js {
	max-height:0;
	-webkit-transition:all .3s ease;
	-o-transition:all .3s ease;
	transition:all .3s ease;
	overflow:hidden
}*/
.single-product div.product {
	overflow:inherit !important
}
.product-blockAll {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	width:100%;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.product-block {
	-webkit-box-ordinal-group:2;
	-ms-flex-order:1;
	order:1;
	width:100%
}
.product-block {
	padding-top:70px;
	padding-bottom:40px
}
/*
.familia--zigzag .title-g__title h1, .familia--zigzag .title-g__title h2, .familia--zigzag .title-g__title h3, .familia--zigzag .title-g__title h4, .familia--zigzag .title-g__title h5 {
	letter-spacing:1.63px
}
.familia--zigzag .title-g__text {
	line-height:1.5
}
.familia-products {
	padding-top:90px;
	padding-bottom:40px
}
.familia-products__list {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.familia-productsItem {
	width:32%;
	margin:0;
	margin-right:2%;
	margin-bottom:2%
}
.familia-productsItem:nth-child(3n+3) {
	margin-right:0
}
@media (max-width:1024px) {
	.familia-products {
		padding-top:50px;
		padding-bottom:30px
	}
	.familia-productsItem {
		width:48%;
		margin-right:4% !important;
		margin-bottom:4% !important
	}
	.familia-productsItem:nth-child(2n+2) {
		margin-right:0 !important
	}
}
@media (max-width:1024px) {
	.familia-products {
		padding-top:40px;
		padding-bottom:20px
	}
}
*/
@media (max-width:480px) {
    /*
	.familia-productsItem {
		width:100%;
		margin-right:0 !important;
		margin-bottom:40px !important
	}
	.familia-productsItem:nth-child(2n+2) {
		margin-right:0 !important
	}
	.familia-productsItem:last-child {
		margin-bottom:0 !important
	}*/
}
/*
.familia--categories .categories-block__container {
	padding:0 !important;
	max-width:100%
}
.familia--categories .categories-block__title {
	max-width:1680px;
	padding:0 40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:100%;
	margin:auto;
	margin-bottom:60px
}
.familia--categories .categories-g__item {
	width:25%
}
@media (max-width:1600px) {
	.familia--categories .categories-g__item {
		width:33.33%
	}
}
@media (max-width:1024px) {
	.familia--categories .categories-g__item {
		width:100%
	}
}
.familiapage--catalogo .banner-g__container {
	padding:0 !important;
	max-width:100%
}
.block-g--familia {
	background:#F1F1F1;
	padding-top:100px;
	padding-bottom:90px
}
@media (max-width:1024px) {
	.block-g--familia {
		padding-top:50px;
		padding-bottom:40px
	}
}*/
/*
.micuenta-title2 {
	text-align:center;
	margin-bottom:55px
}
.micuenta-title2 .title-g__text {
	margin-top:35px;
	line-height:1.4
}
.micuenta {
	padding-top:90px;
	padding-bottom:90px
}
.micuenta .woocommerce-form-register {
	max-width:660px;
	width:100%;
	margin:auto
}
.micuenta .woocommerce-form-login {
	max-width:420px;
	width:100%;
	margin:auto
}
.micuenta address {
	border:1px solid #B3B3B3;
	padding:30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	font-size:17px;
	text-transform:uppercase;
	color:#000;
	font-family:"TTNorms-Regular", sans-serif
}
.micuenta .addresses header.title a {
	width:inherit !important;
	height:inherit !important;
	text-indent:inherit !important;
	font-size:16px !important;
	color:#E3051B !important;
	font-family:"TTNorms-Bold", sans-serif !important;
	letter-spacing:1.2px !important;
	text-transform:uppercase !important;
	position:absolute;
	right:25px;
	bottom:25px
}
.micuenta .addresses header.title a::before {
	position:relative !important;
	height:inherit !important;
	width:inherit !important;
	color:#E3051B;
	font-size:20px
}
.micuenta .u-column1, .micuenta .u-column2 {
	margin:0 !important;
	width:100% !important
}
.micuenta .u-column1 {
	margin-bottom:50px !important
}
.micuenta .u-column1 > h2 {
	display:none !important
}
.micuenta .woocommerce {
	font-family:"TTNorms-Regular", sans-serif !important;
	width:100%;
	margin:auto;
	text-align:center
}
.micuenta .woocommerce-LostPassword a {
	color:#E3051B;
	font-size:15px;
	font-family:"TTNorms-Regular", sans-serif
}
.micuenta .woocommerce-form-login__submit {
	margin-top:25px
}
.micuenta label {
	text-align:left
}
.micuenta .addresses header.title a {
	color:#E3051B
}
.micuenta__container:before {
	content:'';
	display:block;
	clear:both
}
.micuenta__container:after {
	content:'';
	display:block;
	clear:both
}
@media (max-width:1024px) {
	.micuenta {
		padding-top:50px;
		padding-bottom:50px
	}
	.micuenta .u-column1 > h2, .micuenta .u-column2 > h2 {
		font-size:16px !important
	}
}
*/
@media (max-width:1024px) {
	.micuenta address {
		font-size:14px !important
	}
	.micuenta address {
		padding-bottom:60px
	}
	.micuenta .addresses header.title a {
		bottom:20px;
		right:0;
		left:0;
		margin:auto
	}
}
/*
@media (max-width:1024px) {
	.distribuidorportal {
		height:700px
	}
	.distribuidorportal-formTitle > h3 {
		font-size:25px
	}
	.distribuidorportal-formText {
		font-size:18px
	}
	.distribuidorportal-form {
		padding:40px 30px
	}
}
@media (max-width:1024px) {
	.distribuidorportal-image {
		width:45%
	}
	.distribuidorportal-titleForm {
		width:50%
	}
}
@media (max-width:1024px) {
	.distribuidorportal-container {
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center
	}
}
@media (max-width:1024px) {
	.distribuidorportal {
		height:auto;
		padding:60px 0 30px 0
	}
	.distribuidorportal-image {
		width:100%;
		-webkit-box-ordinal-group:2;
		-ms-flex-order:1;
		order:1;
		max-width:300px;
		margin:auto
	}
	.distribuidorportal-titleForm {
		width:100%;
		-webkit-box-ordinal-group:3;
		-ms-flex-order:2;
		order:2;
		margin-top:50px
	}
	.distribuidorportal-form {
		padding:22px 20px 15px 22px
	}
	.distribuidorportal-formTitle > h3 {
		font-size:14px
	}
	.distribuidorportal-formText {
		font-size:14px;
		margin-top:20px
	}
	.distribuidorportal-formPopupSession {
		margin-top:20px
	}
	.distribuidorportal-formPopupSession a {
		font-size:14px
	}
	.distribuidorportal-title {
		margin-bottom:40px
	}
	.distribuidorportal-title .title-g__title h1, .distribuidorportal-title .title-g__title h2, .distribuidorportal-title .title-g__title h3, .distribuidorportal-title .title-g__title h4, .distribuidorportal-title .title-g__title h5 {
		font-size:28px !important
	}
}
*/
/*
.distribuidorportal--Registro.distribuidorportal {
	height:auto;
	padding-top:55px;
	padding-bottom:110px
}
.distribuidorportal--Registro .distribuidorportal-container {
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.distribuidorportal--Registro .distribuidorportal-titleForm {
	width:63%
}
.distribuidorportal--Registro .distribuidorportal-titleForm .distribuidorportal-form {
	max-width:100%
}
.distribuidorportal--Registro .distribuidorportal-titleForm .distribuidorportal-form .form-block__text {
	font-size:15px
}
.distribuidorportal--Registro .distribuidorportal-image {
	width:30.5%;
	margin-top:100px
}
@media screen and (max-width:1024px) {
	.distribuidorportal--Registro.distribuidorportal {
		padding-top:50px;
		padding-bottom:50px
	}
	.distribuidorportal--Registro .distribuidorportal-titleForm {
		width:100%
	}
	.distribuidorportal--Registro .distribuidorportal-image {
		display:none
	}
}
@media screen and (max-width:1024px) {
	.distribuidorportal--Registro .distribuidorportal-titleForm {
		margin-top:0
	}
	.distribuidorportal--Registro .distribuidorportal-titleForm .distribuidorportal-title {
		padding-left:40px;
		padding-right:40px
	}
	.distribuidorportal--Registro .distribuidorportal-container {
		padding:0 !important
	}
	.distribuidorportal--Registro .distribuidorportal-form {
		border-radius:0;
		padding:25px 40px 35px 40px
	}
	.distribuidorportal--Registro.distribuidorportal {
		padding-top:35px;
		padding-bottom:0
	}
	.distribuidorportal-title {
		margin-bottom:35px;
		padding-left:0;
		padding-right:0
	}
}
*/
.woocommerce-message, .woocommerce-info, .woocommerce-error, .woocommerce-noreviews, p.no-comments {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c)) !important;
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%) !important;
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%) !important
}
/*.footer-messageCourses {
	background:#F1F1F1;
	padding-top:60px;
	padding-bottom:60px
}*/
