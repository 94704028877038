div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

div.wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
	border: 2px solid #ff0000;
}

.wpcf7-form-control-wrap {
	position: relative;
}

span.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
	content: " ";
}

.wpcf7-display-none {
	display: none;
}

div.wpcf7 .ajax-loader {
	visibility: hidden;
	display: inline-block;
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
}

div.wpcf7 .ajax-loader.is-active {
	visibility: visible;
}

div.wpcf7 .wpcf7-submit:disabled {
	cursor: not-allowed;
}
