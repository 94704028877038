html {
	font-family:sans-serif;
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
}
body {
	margin:0;
}
article, figure, footer, header, main, menu, nav, section {
	display:block;
}
video {
	display:inline-block;
	vertical-align:baseline;
}
[hidden] {
	display:none;
}
a {
	background-color:transparent;
}
a:active, a:hover {
	outline:0;
}
b, strong {
	font-weight:700;
}
img {
	border:0;
}
svg:not(:root) {
	overflow:hidden;
}
button, input, select, textarea {
	color:inherit;
	font:inherit;
	margin:0;
}
button {
	overflow:visible;
}
button, select {
	text-transform:none;
}
button[disabled], html input[disabled] {
	cursor:default;
}
button::-moz-focus-inner, input::-moz-focus-inner {
	border:0;
	padding:0;
}
input {
	line-height:normal;
}
input[type=checkbox], input[type=radio] {
	box-sizing:border-box;
	padding:0;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	height:auto;
}
input[type=search] {
	-webkit-appearance:textfield;
	box-sizing:content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
	-webkit-appearance:none;
}
textarea {
	overflow:auto;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
td, th {
	padding:0;
}
body {
	-ms-word-wrap:break-word;
	word-wrap:break-word;
}
body, button, input, textarea {
	color:#43454b;
	line-height:1.618;
	text-rendering:optimizeLegibility;
	font-weight:400;
}
select {
	color:initial;
	max-width:100%;
}
h1, h2, h3, h4, h5, h6 {
	clear:both;
	font-weight:300;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	font-weight:300;
}
ul {
	margin:0 0 1.41575em 3em;
	padding:0;
	list-style:disc;
}
li > ul {
	margin-bottom:0;
	margin-left:1em;
}
em, i {
	font-style:italic;
}
address {
	margin:0 0 1.41575em;
}
figure {
	margin:0;
}
table {
	margin:0 0 1.41575em;
	width:100%;
}
img {
	height:auto;
	max-width:100%;
	display:block;
}
a {
	color:#2c2d33;
	text-decoration:none;
}
* {
	box-sizing:border-box;
}
body {
	background-color:#fff;
	overflow-x:hidden;
}
.screen-reader-text {
	border:0;
	clip:rect(1px, 1px, 1px, 1px);
	-webkit-clip-path:inset(50%);
	clip-path:inset(50%);
	height:1px;
	margin:-1px;
	overflow:hidden;
	padding:0;
	position:absolute;
	width:1px;
	word-wrap:normal !important;
}
.screen-reader-text:focus {
	background-color:#f1f1f1;
	border-radius:3px;
	box-shadow:0 0 2px 2px rgba(0, 0, 0, .6);
	clip:auto !important;
	display:block;
	font-weight:700;
	height:auto;
	left:0;
	line-height:normal;
	padding:1em 1.618em;
	text-decoration:none;
	top:0;
	width:auto;
	z-index:100000;
	outline:0;
}
.clear::after, .clear::before {
	content:'';
	display:table;
}
.clear::after {
	clear:both;
}
table {
	border-spacing:0;
	width:100%;
	border-collapse:separate;
}
table td, table th {
	padding:1em 1.41575em;
	text-align:left;
	vertical-align:top;
}
table td p:last-child, table th p:last-child {
	margin-bottom:0;
}
table th {
	font-weight:600;
}
table thead th {
	padding:1.41575em;
	vertical-align:middle;
}
table tbody h2 {
	font-size:1em;
	letter-spacing:normal;
	font-weight:400;
}
table tbody h2 a {
	font-weight:400;
}
form {
	margin-bottom:1.618em;
}
button, input, select, textarea {
	font-size:100%;
	margin:0;
	vertical-align:baseline;
}
.added_to_cart, .button, button, input[type=button], input[type=reset], input[type=submit] {
	border:0;
	border-radius:0;
	background:0 0;
	background-color:#43454b;
	border-color:#43454b;
	color:#fff;
	cursor:pointer;
	padding:.6180469716em 1.41575em;
	text-decoration:none;
	font-weight:600;
	text-shadow:none;
	display:inline-block;
	-webkit-appearance:none;
}
input[type=checkbox], input[type=radio] {
	padding:0;
}
input[type=checkbox] + label, input[type=radio] + label {
	margin:0 0 0 .3819820591em;
}
input[type=search]::-webkit-search-decoration {
	-webkit-appearance:none;
}
input[type=search] {
	box-sizing:border-box;
}
input[type=search]::-webkit-input-placeholder {
	color:#616161;
}
input[type=search]:-ms-input-placeholder {
	color:#616161;
}
input[type=search]::-ms-input-placeholder {
	color:#616161;
}
input[type=search]::placeholder {
	color:#616161;
}
button::-moz-focus-inner, input::-moz-focus-inner {
	border:0;
	padding:0;
}
.input-text, input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], textarea {
	padding:.6180469716em;
	background-color:#f2f2f2;
	color:#43454b;
	border:0;
	-webkit-appearance:none;
	box-sizing:border-box;
	font-weight:400;
	box-shadow:inset 0 1px 1px rgba(0, 0, 0, .125);
}
.input-text:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=url]:focus, textarea:focus {
	background-color:#ededed;
}
textarea {
	overflow:auto;
	padding-left:.875em;
	vertical-align:top;
	width:100%;
}
label {
	font-weight:400;
}
label.inline input {
	width:auto;
}
[placeholder]:focus::-webkit-input-placeholder {
	-webkit-transition:opacity .5s .5s ease;
	transition:opacity .5s .5s ease;
	opacity:0;
}
img {
	border-radius:3px;
}
iframe, object, video {
	max-width:100%;
}
.widget {
	margin:0 0 3.706325903em;
}
.widget select {
	max-width:100%;
}
.widget ul {
	margin-left:0;
	list-style:none;
}
.widget ul ul {
	margin-left:1.618em;
}
@media (min-width:768px) {
	table.shop_table_responsive tbody tr td, table.shop_table_responsive tbody tr th {
		text-align:left
	}
	ul.menu li.current-menu-item > a {
		color:#43454b
	}
}
