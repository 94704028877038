.button-g--white .button-g__text {
    letter-spacing: normal;
}
.slider-principal__infoTitle h1 u::before {
    height:5px;
}
.partial-underline u {
    position:relative;
    display:inline-block;
	padding-bottom:7px;
	text-decoration:none;
}
.partial-underline u:before {
	content:"";
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	height:4px;
	width:100%;
	position:absolute;
	bottom:0;
	left:0
}
.slider-principal__infoTitle.white {
    color:#ffffff;
}
.slider-principal__infoTitle.black {
    color:#43454b;
}
.woocommerce-button, .woocommerce-Button, .button {
    height:40px !important;
    line-height:40px !important;
    font-size:13px !important;
}
.input-g--select .input-g__input {
    padding-right:32px !important;
}
h1, h2, h3, h4, h5, h6 {
    color:inherit;
}
.main-header__list > li:first-child {
    margin-left:5%;
}
.main-header__list > li > a {
    font-size:.8rem;
}
.current_page_item a {
    font-weight:600;
}
#popUp-gGuia .colorSilver6 {
    color:#FFF;
    text-decoration:underline;
}
.ajax-form .button-g {
    margin-bottom:19px;
}
.arrow-g__button {
    background:url("../img/icons/left-arrow.svg");
}
.arrow-g__buttonNext {
    background:url("../img/icons/right-arrow.svg");
}
.main-footer__email,.main-footer__telephone {
    width:49%;
    display:inline-block;
}
.main-footer__email {
    margin-bottom:0px;
}
.miga-g > ul > li::after {
    background:url("../img/icons/breadcrumb.svg");
}
.popUp-gOlvidaste .popUp-g__popUp {
	height:300px;
	padding:10px 0;
}
.popup-footer-link a {
    color:#FFF;
    text-transform:uppercase;
    border-bottom:1px #FFF solid;
    position:absolute;
    bottom:12px;
    right:28px;
    font-size:.75rem;
}
.banner-g__info.black h1 {
    color:black;
}
.input-g__checkboxWP label > span {
    color:#000;
}
.account-edit-header-link {
    color:#E3051B;
    font-family:"TTNorms-Bold",sans-serif;
    margin-right:60px;
    font-size:85%;
}
.account-edit-header-link img {
    display:inline-block;
    height:18px;
    margin-right:8px;
    margin-bottom:-3px;
}
.input-g__title.fontBold, .portalgp-rplataformaTitle.garanty > h2.fontBold {
    font-family:"TTNorms-Bold",sans-serif;
}
.label-two-columns > .input-g {
    margin-bottom:2px;
}
#edit-profile--form .input-g__line .input-g {
    margin-bottom:8px;
}
.mobile-contact-button {
    display:none;
}
.zigzap-g__item .zigzap-g__title .title-g__text {
    text-align:justify;
}
.icon-exclamation::before {
    content:"!";
    font-family:"TTNorms-Bold",sans-serif;
}
.hide-mobile {
    display:none;
}
footer .menu-item {
    text-rendering:optimizespeed;
}
span.tooltip {
    display:none;
    position:absolute;
    line-height:22px;
    padding:20px;
    font-size:.875rem;
    color:#000;
    background:rgb(255, 255, 255);
    border-radius:8px;
    font-family:"TTNorms-Regular",sans-serif;
    text-align:left;
    right:-180px;
    bottom:50px;
}
span.tooltip.active {
    display:inline-block;
}
.item-g4__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
span.tooltip:after {
    content:"";
    position:absolute;
    width:0;
    height:0;
    border-width:10px;
    border-style:solid;
    border-color:#FFFFFF transparent transparent transparent;
    top:100%;
    left:30%;
}
span.tooltip ul {
    margin:0 12px 10px;
    list-style:none;
}
span.tooltip ul li::before {
    content:"\2022";  /* Add content:\2022 is the CSS Code/unicode for a bullet */
    color:#e2051b; /* Change the color */
    font-weight:bold; /* If you want it to be bold */
    display:inline-block; /* Needed to add space between the bullet and the text */
    width:1em; /* Also needed for space (tweak if needed) */
    margin-left:-1em; /* Also needed for space (tweak if needed) */
}
span.tooltip a {
    color:#e2051b;
    position:initial;
    font-family:"TTNorms-Bold",sans-serif;
    font-size:.875rem;
    letter-spacing:0;
}
.form-error {
    font-family:"TTNorms-Regular",sans-serif;
}
.login-remember label {
    font-family:"TTNorms-Regular",sans-serif;
    color:#1D1D1B;
}
.input-g__checkboxWP label > input {
    visibility:initial !important;
}
.stylesAll-content {
    line-height:1.7;
}
.login-submit {
    text-align: center;
}
.olvidasteplataforma--js, .portalgp-blockInfoButton {
    text-align: center;
}
.block-distribuidor__formbtn1 input[type="submit"] {
    font-weight:400;
}
#edit-profile-save {
    margin-right:.75em;
}
.login-submit input {
    font-weight:400;
}
.hon {
    display:none;
}
/* Mobile */
@media (max-width:767px) {
    .ta-center {
        text-align: center;
    }
    .ta-left {
        text-align: left !important;
    }
    .main-header__logo {
        width:100%;
        margin-left:-15px;
    }
    .categories-block .title-g__fz--38 {
        letter-spacing:2px !important;
    }
    .item-descarga__listInButton span:first-child {
        display:none;
    }
    .categories-block .title-g__fz--24.fontLight {
        font-size:16px;
    }
    .catproducto--categories .categories-block__title {
        margin-bottom:30px;
    }
    .footer-links__image img {
        height: 100%;
    }
    .mobile-dl-button {
        padding:0 !important;
        display:block;
    }
    .mobile-dl-button img {
        width:100%;
        height:100%;
    }
    .center-mobile {
        text-align:center;
    }
    .home-contact .title-g--block::before {
        left:0 !important;
        right:0;
        margin:auto;
        width:42% !important;
    }
    .categories-g__name {
        width:50%;
    }
    .title-g__fz--38 {
        font-size:22px;
    }
    .banner-g--asesoria .title-g__buttons {
        text-align:center;
    }
    .main-header__logo {
        max-width:180px;
        margin-left:-20px;
    }
    .slider-principal__infoTitle h1 i::before {
        height:3px;
    }
    .banner-g__info {
        margin-top:1.5em;
    }
    .banner-g--guia .banner-g__image {
        height:628px;
    }
    .home--guia .title-g__fz--48, .home--guia .title-g__button {
        text-align:center;
    }
    .banner-g__container {
        height:610px;
    }
    .home--asesoria {
        background:#FFFFFF;
    }
    .home--asesoria .banner-g--asesoria .banner-g__image {
        height:321px;
    }
    .banner-g__container .title-g__fz--48 {
        text-align:center;
    }
    .main-footer__columns .swiper-pagination-bullet-active {
        background:#E3051B;
    }
    .slider-principal__infoTitle h1 u::before {
        height:4px;
    }
    .mobile-hide {
        display:none;
    }
    .mobile-contact-button {
        text-align:center;
        display:block !important;
    }
    .zigzap-g__item .zigzap-g__title .title-g__text {
        margin-top:30px;
    }
    .zigzap-g__item .zigzap-g__title .title-g__text {
        margin-top:30px;
    }
    .home--categories .title-g__fz--38.fontLight.colorBlack.g--uppercase {
        text-align:center;
    }
    .productpage--categories .title-g__fz--38.fontLight.colorBlack.g--uppercase {
        text-align:left;
    }
    .banner-g--nosotros .title-g__fz--55.fontLight.colorWhite.g--uppercase {
        text-align:left;
    }
    .banner-g--nosotros .title-g--border::before {
        display:none;
    }
    .block-text--nosotros {
        padding:80px 0;
    }
    .nosotros--zigzag .zigzap-g__item {
        flex-direction:column-reverse;
    }
    #compromisos .block-g__image > img {
        max-height:38px;
    }
    #compromisos .block-g__acordionContent p {
        padding-left:52px;
    }
    .aboutus-clients__imgs figure img {
        max-width:120px;
    }
    .home-contact .title-g--block {
        font-size:24px;
        padding:0 16px;
    }
    .home-contact .title-g--block::before {
        content:"";
        background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
        background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
        background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
        height:4px;
        width:70%;
        position:absolute;
        bottom:0;
        left:15%;
    }
    .item-descarga__listInTitle {
        margin-bottom:7px;
    }
    .home-contact .title-g--border::before {
        display:none;
    }
    .main-footer__newsletter, .main-footer__socialnetwork {
        text-align:left;
    }
    .main-burguer--close > span:first-child {
        transform: translate(6px, 12px) rotate(-45deg);
        transform-origin: 0% 0%;
    }
    .main-burguer--close > span:last-child {
        transform: translate(5px, -12px) rotate(45deg);
    }
    .main-burguer--close>span:nth-child(2) {
        opacity: 0;
    }
    .main-sidebar__language ul > li:not(.current-lang) a {
        font-family:"TTNorms-Regular",sans-serif;
    }
    .home--catalogo .banner-g__image, .home--catalogo .banner-g__container, .home--catalogo .banner-g--catalog, .home--catalogo .banner-g--catalog .banner-g__image {
        height:595px;
    }
    .banner-g__info .title-g__title h2, .banner-g__info .title-g__title h3, .banner-g__info .title-g__title h4, .banner-g__info .title-g__title h5 {
        text-align:center;
    }
    .portalgp-blockInfoTitle > h3, .portalgp-blockLoginTitle > h3 {
        font-weight:bold;
        margin-bottom:1.5em;
    }
    .portalgp-blockLogin .portalgp-blockInAll {
        width:100%;
    }
    .portalgp-rcompany .form-block__button {
        text-align:center;
    }
    #registrar_lista_producto .button-g__text {
        z-index:initial;
    }
    .banner-g p {
        text-align:justify;
    }
    .home--catalogo .title-g__button {
        text-align:center;
    }
    .home--catalogo {
        padding-bottom:0 !important;
    }
    .zigzap-g__title .title-g__title {
        text-align:center;
    }
    .zigzap-g__title .title-g__icon {
        margin-left:auto;
        margin-right:auto;
    }
    .banner-g--garantia .banner-g__info {
        text-align:center;
    }
    .account-edit-header-link {
        display:block;
        margin-bottom:24px;
    }
    .center--zigzag .zigzap-g__item {
        flex-direction:column-reverse;
    }
    .title-g__icon img {
        max-width:48px;
    }
    .center--zigzag .title-g__button {
        text-align:center;
    }
    .block-distribuidor__item .title-g__icon img {
        max-width:44px;
        margin-left:auto;
    }
    .block-distribuidor__item .title-g__icon {
        width:100%;
    }
    .block-distribuidor__b2 .title-g__button {
        text-align:center;
    }
    .block-g--distribuidores .block-g__image {
        width:48px;
    }
    .banner-g--distribuidores .banner-g__inside {
        align-items:flex-start;
    }
    .distribuidores-form .form-block__nav {
        padding-bottom:30px;
    }
    .distribuidores-form .form-block__navTitle {
        padding-top:30px;
        padding-bottom:30px;
    }
    .portalgp-blockInfo {
        padding:40px;
    }
    .portalgp-blockInfoTitle > h3 {
        text-align:center;
    }
    .portalgp-blockInfoLogin {
        margin:0 20px;
    }
    .portalgp-blockInfoLogin.garantia {
        flex-direction:column;
    }
    .portalgp-blockLogin {
        text-align:center;
    }
    .portalgp-blockInfoLogin {
        border-radius:10px !important;
    }
    .portalgp-blockInfoLogin.garantia .portalgp-blockInfo {
        text-align:center;
    }
    .portalgp-blockInfoLogin.garantia {
        margin:0 20px;
    }
    .woocommerce-button, .woocommerce-Button, .button {
        padding:0 16px !important;
    }
    .portalgp-blockInfoLogin .button-g__text {
        height:40px;
        line-height:40px;
    }
    .portalgp-blockLogin input[type="text"], .portalgp-blockLogin input[type="password"] {
        font-size:15px !important;
    }
    .olvidaste--js, .olvidastecursos--js, .olvidasteplataforma--js {
        font-size:15px;
    }
    .portalgp-blockLoginTitle > h3 {
        margin-bottom:20px;
    }
    .portalgp-blockInfoText {
        text-align:justify;
    }
    .attachment-post-thumbnail.size-post-thumbnail.wp-post-image {
        width:100%;
    }
    .login-submit input {
        font-size:12px !important;
    }
    .title-fz {
        font-size: 23px;
    }
}
/* Portait */
@media (min-width:768px) and (max-width:768px) and (orientation: portrait) {
    .slider-principal__bg ,
    .slider-principal__container,
    .slider-principal,
    .banner-g--nosotros .banner-g__image,
    .banner-g--soporte .banner-g__image,
    .banner-g--distribuidores .banner-g__image,
    .banner-g--catproducto .banner-g__image,
    .banner-g--distribuidoresconv .banner-g__image {
        height: 870px;
    }
    .banner-g--guia .banner-g__image,
    .banner-g--asesoria .banner-g__image,
    .banner-g--catalog .banner-g__image,
    .banner-g--garantia .banner-g__container,
    .banner-g--garantia .banner-g__image,
    .banner-g--averia .banner-g__container,
    .banner-g--averia .banner-g__image {
        height:340px;
    }
    .zigzap-g__img {
        height: 606px;
    }
}
/* Landscape */
@media (min-width:1024px) and (max-width:1024px) and (orientation: landscape) {
    .slider-principal__bg,
    .slider-principal__container,
    .slider-principal,
    .banner-g--nosotros .banner-g__image,
    .banner-g--soporte .banner-g__image,
    .banner-g--distribuidores .banner-g__image,
    .banner-g--catproducto .banner-g__image,
    .banner-g--distribuidoresconv .banner-g__image {
        height: 640px;
    }

    .banner-g--catalog .banner-g__image,
    .banner-g--garantia .banner-g__container,
    .banner-g--garantia .banner-g__image,
    .banner-g--averia .banner-g__image {
        height: 453px;
    }
    .zigzap-g__img {
        height: 808px;
    }

    .categories-g__item {
        height: 200px;
    }

    .banner-g--guia .banner-g__image,
    .banner-g--guia .banner-g__container,
    .banner-g--asesoria .banner-g__image,
    .banner-g--asesoria .banner-g__container,
    .banner-g--averia .banner-g__container {
        height: 453px;
    }
}
/* Tablet */
@media (min-width:768px) and (max-width:1024px) {
    .slider-principal__infoTitle h1 {
        letter-spacing:3px;
    }
    .categories-block .title-g__fz--38 {
        font-size:25px;
    }
    .footer-links__item {
        height:160px;
    }
    .main-burguer--close>span:first-child {
        transform: translate(6px, 12px) rotate(-45deg);
        transform-origin: 0% 0%;
    }
    .main-burguer--close>span:last-child {
        transform: translate(5px, -12px) rotate(45deg);
    }
    .main-burguer--close>span:nth-child(2) {
        opacity: 0;
    }
    .categories-g__name{
        width:60%;
    }
    .main-footer__newsletter, .main-footer__socialnetwork {
        text-align: left;
    }
    .main-footer__socialnetwork{
        width: auto;
    }
    .block-g--distribuidores .block-g__image {
        width:45px;
    }
    .title-tablet{
        margin-top:100px;
    }
    .title-tablet h1{
        font-size:40px;
    }
    .title-tablet .title-g__text{
        font-size:16px;
    }
    .title-fz40-tablet{
        font-size: 40px;
    }
    .title-fz30-tablet {
        font-size: 30px!important;
    }
    .title-fz26-tablet {
        font-size: 26px !important;
    }
    .home--categories .categories-block__title .title-g__title h2 {
        letter-spacing: normal;
    }
    .banner-g--catalog .banner-g__info{
        margin: auto 0 auto auto;
    }
    .banner-g--catalog .banner-g__info h3 {
       font-size:30px;
    }
    .popUp-gcontacto .popUp-g__popUp {
        height: auto;
    }
    .title-g__icon{
        margin:0 auto;
    }
    .nosotros--zigzag .zigzap-g__title .title-g__title {
        margin:0 auto;
    }
    .categories-g__name>h3 {
        font-size: 20px;
    }
    .tablet-container-padding{
        padding: 0 60px !important; /*other class using important so i have to override*/
    }
    .tablet-container-padding-card {
        padding: 5% 60px !important;
        /*other class using important so i have to override*/
    }
    .newsDetail-info{
        padding:0;
    }
    .productpage--categories{
        padding-bottom:0;
    }
    .noPadding-bottom{
        padding-bottom:0 !important; /*other class using important so i have to override*/
    }
    .ta-center {
        text-align:center!important;
    }
    .ta-left {
        text-align:left !important;
    }
    .m-auto{
        margin:auto!important;
    }
    .f-center {
        display:flex;
        justify-content:center;
    }
    .title-fz {
        font-size:30px;
    }
    .title-fz-50 {
        font-size:50px;
    }
    .title-banner-fz30{
        font-size:30px!important;
        letter-spacing:0.75px!important;
        line-height: normal!important;
    }
    .no-padding{
        padding:0!important;
    }
    .zigzap-g__item .zigzap-g__title .title-g__text {
        text-align:justify;
        font-size:16px;
    }
    .zigzap-g__info, .zigzap-g__item:nth-child(2n) .zigzap-g__info {
        padding:85px 60px;
    }
    .banner-g--garantia .banner-g__info {
        margin:auto 0 auto auto;
    }
    .banner-g--garantia .banner-g__info .title-g__buttons,
    .banner-g--soporte .banner-g__info {
        margin-top:0;
    }
    .banner-g--garantia .banner-g__info .title-g__text {
        margin-top:10px;
        line-height:1.2;
    }
    .banner-g--garantia .banner-g__inside {
        padding: 0 10%;
    }
    .banner-g--averia .banner-g__info {
        margin: auto auto auto 0;
    }
    .banner-g--averia .banner-g__info .title-g__text {
        margin-top:10px;
    }
    .banner-g--averia .banner-g__inside {
        padding: 0 10%;
    }
    .banner-g--garantia .title-fz {
        line-height:1;
    }
    .find-service-title {
        width:300px;
        margin:30px auto 10px auto;
    }
    .input-find-service {
        margin:auto;
        width:300px;
    }
    .input-downloads {
        margin:0;
        width:300px;
    }
    .title-downloads h1{
        font-size: 25px;
    }
    .title-downloads h1 {
        margin-top: 60px;
    }
    .item-descarga__listInButton span:first-child {
        display: none;
    }
    .mobile-dl-button img {
        width: 100%;
        height: 100%;
    }
    .filter--descargas .filter-block__result {
        padding: 50px;
    }
    .filter--descargas .filter-block__container {
        padding:0 60px !important;
    }
    .filter--descargas .filter-block__filter {
        padding:40px 0;
    }
    .filter--descargas .filter-block__title {
        padding:0;
    }
    .distributor-banner {
        align-items: start;
    }
    .distributor-banner .title-g__title {
        margin-top:100px;
    }
    .distribuidorsearch-posts {
        display:none;
    }
    .distribuidorsearch-form {
        padding:0;
    }
    .distribuidorsearch-search {
        padding:60px 10% 20px 10%;
    }
    .portalgp-blocks {
        flex-direction:column-reverse;
    }
    .portalgp-menu {
        display:none;
    }
    .portalgp-block1 {
        width:100%;
    }
    .portalgp-block2 {
        width:100%;
    }
    .portalgp-block2 h1 {
        display:none;
    }
    .portalgp-block2 .filter-block__title {
        margin-top:0;
        margin-bottom:40px;
    }
    .portalgp-blockInfoLogin .button-g__text {
        padding:0 15px!important;
    }
    .block-g__title2 br {
        display:none;
    }
    .block-g__title2>h3 {
        font-size:20px;
    }
    .partial-underline u::before {
        content: "";
        background: linear-gradient(to right, #e2051b 0%, #e9590c 100%);
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .item-g__familiaInfo .item-g__familiaText,
    .item-g__product3Info .item-g__product3Cat {
        display:none;
    }
    .mw-53{
        max-width:53px;
    }
    .item-g4__item {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .item-g4__info {
        flex-grow: 1;
    }
    .block-g__title2 {
        padding-left:30px;
    }
    .productpage--categories .categories-block__title{
        text-align:left;
    }
    .categories-g__name>h3 {
        font-size: 24px;
    }
   .item-descarga__list {
       padding: 25px 60px 10px 60px;
    }
    .padding-list {
        padding: 25px 30px 10px 30px;
    }
    .portalgp-blockInfo .portalgp-blockInAll,
    .portalgp-blockLogin .portalgp-blockInAll {
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       height: 100%;
    }

    .portalgp-blockInfoButton .button-g__text {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }
    .login-submit {
        margin:0;
    }
    .login-submit .button {
         height:40px !important;
         line-height:40px;
    }
    .item-g4__title h3 {
       letter-spacing: normal;
    }
    .item-g4__text span,
    .item-g4__text p {
        font-size: 12px;
    }
    .stylesAll-content {
        font-size: 16px;
    }
    .block-g__image img {
        height: 38px;
    }
    .wrapper-container {
        padding: 0 60px;
    }
    .slider-principal__info{
        padding: 40px 0;
    }
    .categories-g__name > h3{
        letter-spacing: 2px;
    }
    .footer-links__image img {
        width: 42px;
        height:42px;
    }
    .item-descarga__title{
        min-height: 56px;
    }
    .filter--descargas .filter-block__result{
        padding: 40px 0;
    }
    .portalgp-block1Menu>ul>li.current_page_item>a {
        font-size: 23px;
    }
    .footer-links__text > h3 {
        font-size: 16px;
    }
    .title-g--border::before,
    #searchdistribuidor h3 u::before {
        height: 3px;
    }
    .title-g--border,
    .partial-underline u,
    #searchdistribuidor h3 u {
        padding-bottom:4px;
    }
    .table__g--responsive tbody td:last-child {
        padding-bottom: 20px!important;
    }
    .table__g--responsive .table__g__responsive__cell::before {
        font-size: 12px;
    }
    .portalgpGarantia .portalgp-block1Menu {
        margin-bottom: 15px;
    }
    .table__g--responsive tbody td:last-child {
        padding-bottom: 20px!important;
    }
    .table__g--responsive .table__g__responsive__cell::before {
        font-size: 12px;
    }
    .portalgpGarantia .portalgp-block1Menu {
        margin-bottom: 15px;
    }
    .table__g--responsive tbody td:last-child {
        padding-bottom: 20px!important;
    }
    .table__g--responsive .table__g__responsive__cell::before {
        font-size: 12px;
    }
    .banner-g--asesoria .banner-g__info .title-g__text {
        margin-top:25px;
    }
    .titleText-g__fz--16 {
        font-size: 16px;
    }
    .banner-g__info .title-g__title h2 {
        letter-spacing:0.75px;
        font-size:30px;
        line-height:normal;
    }
    .home-zigzag .zigzap-g__title .title-g__title h2,
    .title-g__title h3 {
        letter-spacing: 0.75px;
    }
    .portalgp-blockInfo,
    .portalgp-blockLogin {
        padding:45px;
        height:500px;
    }
    .popUp-g__popUp {
        height: auto !important;
    }
}
/* Desktop and up */
@media (min-width:1025px) {
    .categories-block h3, .categories-block h1 {
        letter-spacing:2px;
    }
    .popUp-gcontacto .popUp-g__popUp, .popUp-gGuia .popUp-g__popUp, .popUp-gCatalogo .popUp-g__popUp {
        height:auto;
    }
    .slider-principal__infoTitle h1 {
        line-height:1.2;
    }
    .login-remember {
        font-size:15px;
    }
    .login-submit {
        margin:0;
    }
    .portalgp-blockInfo .portalgp-blockInAll, .portalgp-blockLogin .portalgp-blockInAll {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        height:100%;
    }
    /* Header banner fixes */
    .banner-g__info.right {
        position:absolute;
        right:10%;
    }
    .portalgp-rProductosCod {
        width:30%;
    }
    .portalgp-rProductosModelo {
        width:30%;
    }
    .mobile-dl-button {
        display:none;
    }
    .form-block__button {
        overflow:hidden !important;
    }
}
/* Desktop */
@media (min-width:1200px) and (max-width:1600px)  {
    .filter--descargas .title-g__fz--24 {
        letter-spacing:1.5px;
    }
    .politicas-block {
        padding-top:50px;
        padding-bottom:50px;
    }
    .politicas-block__content {
        font-size:16px;
        margin-top:40px;
    }
    .politicas-block strong, .politicas-block b {
        font-family:"TTNorms-Medium",sans-serif;
    }
    .slider-principal__bg {
        height:580px;
    }
    .slider-principal__infoTitle h1 u::before {
        height:5px;
    }
    .slider-principal__infoText {
        font-size:16px;
    }
    .slider-principal, .slider-principal__container, .banner-g--catproducto .banner-g__image {
        height:580px;
    }
    .slider-principal__info {
        padding-bottom:0;
    }
    .wrapper-container {
        padding:0 120px;
    }
    .main-header__logo {
        width:23%;
    }
    .banner-g .banner-g__image {
        height:580px;
    }
    .main-header,.main-header__all {
        height:100px;
    }
    .slider-principal__infoTitle h1 {
        font-size:56px;
    }
    .zigzap-g .title-g__fz--65 {
        font-size:50px;
    }
    .zigzap-g .zigzap-g__info {
        padding:40px 120px;
    }
    .categories-g__name {
        width:45%;
    }
    .banner-g--guia {
        padding:0 120px;
    }
    .banner-g--guia .banner-g__image {
        height:340px;
    }
    .title-g--block {
        display:inline;
    }
    .home-contact__form {
        max-width:700px;
    }
    .contact-form .input-g__lineTwoColumns .input-g {
        margin-bottom:10px;
    }
    .contact-form .input-g__line .input-g {
        margin-bottom:10px;
    }
    .home-contact__form.input-g__checkboxWP label > span {
        color:#000;
    }
    .slider-principal__infoButton .button-g__text {
        height:50px;
        line-height:50px;
    }
    .block-distribuidor__b1 {
        padding-left:120px;
    }
    .block-distribuidor__b2 {
        padding-right:120px;
    }
    .block-text {
        background:linear-gradient(to top, #e2051b 0%, #e9590c 100%);
        padding:80px 0;
    }
    .block-text__title .title-g__text {
        margin-top:36px;
    }
    .block-text--nosotros .block-text__title .title-g__text {
        line-height:1.8;
    }
    .block-text {
        background:linear-gradient(to right, #e2051b 0%, #e9590c 40%);
        padding:90px 0;
    }
    .nosotros--zigzag .zigzap-g__title .title-g__icon img {
        width:72px;
    }
    .block-g__title2 > h3 {
        font-size:16px;
    }
    .block-g__textIn {
        font-size:14px;
    }
    .aboutus-clients__imgs {
        flex-wrap:nowrap;
    }
    .aboutus-clients {
        padding:80px 0;
    }
    .aboutus-clients .block-g__title {
        margin-bottom:25px;
    }
    #compromisos .block-g__item .block-g__imageText img {
        height:44px;
        width:44px;
    }
    #compromisos .block-g__title2 > h3 {
        font-family:"TTNorms-Normal",sans-serif;
        letter-spacing:1.5px;
    }
    .footer-links__item {
        height:160px;
    }
    .footer-links__text > h3 {
        font-size:16px;
    }
    .footer-links__image {
        max-width:42px;
    }
    .main-footer__social > ul > li > a {
        width:40px;
        height:40px;
        line-height:200%;
    }
    .main-footer__email > h3, .main-footer__telephone > h3 {
        font-size:14px;
    }
    .main-footer__email > a, .main-footer__telephone > a {
        font-size:14px;
    }
    .main-footer__list > li > a {
        font-size:13px;
    }
    .main-footer__newsletterTitle > h3 {
        font-size:13px;
    }
    .main-footer__newsletterTitle > p {
        font-size:13px;
    }
    .main-footer__newsletterForm .input-g__input {
        height:40px !important;
    }
    .main-footer__socialnetworkTitle > h3 {
        font-size:13px;
    }
    .main-footer__socialnetworkTitle > p {
        font-size:13px;
    }
    .main-footer__address {
        font-size:13px;
    }
    .main-footer__copyrightText {
        font-size:13px;
    }
    .main-footer__list2 > li > a {
        font-size:13px;
    }
    .main-footer__email > a, .main-footer__telephone > a {
        font-size:13px;
    }
    /*Products*/
    .categories-g__name > h3 {
        font-size:18px;
    }
    .categories-g__name > h3 {
        font-size:18px;
    }
    .categories-g__item {
        height:220px;
    }
    .catproducto--categories .categories-g__item {
        height:180px;
    }
    .catproducto--categories .categories-g__name > h3 {
        font-size:16px;
    }
    .catproducto--categories .categories-g__item {
        width:25%;
    }
    .productd-tabIdealItemTitle > h3 {
        font-size:10px;
    }
    .banner-g--catalog.full-width {
        padding:0;
    }
    .productdetail-miga {
        padding-bottom:0;
    }
    .productdetail {
        padding-top:50px;
        padding-bottom:40px;
    }
    /*Catalogs Banner*/
    .home--catalogo {
        padding-bottom:0 !important;
        background:none;
    }
    .banner-g--catalog {
        padding:0 120px;
    }
    .banner-g--catalog {
        height:340px;
    }
    .banner-g--catalog .banner-g__image {
        height:340px;
    }
    /* Product Detail */
    .miga-g > ul > li > a {
        font-size:18px;
        font-size:15px;
    }
    .item-g__familiaTitle > h3 {
        font-size:28px;
    }
    .catproducto--categories .categories-block__title {
        padding:0 40px;
        padding:0 120px;
    }
    /* Product Detail */
    .productdetail-title > h1 {
        font-size:36px;
    }
    .productdetail-categorie, .productdetail-family {
        font-size:18px;
    }
    .productdetail-excerpt {
        font-size:16px;
    }
    .productd-tabacordion__list > ul > li {
        height:44px;
        padding:6px;
        font-size:13px;
    }
    .productd-tabTitle > h2 {
        font-size:28px;
    }
    .productd-tabContent {
        font-size:14px;
    }
    .productdetail-image .swiper-pagination-bullet {
        width:10px;
        height:10px;
    }
    .productd-tabCuentaBlock img {
        width:44px;
        height:44px;
    }
    /* Support */
    .button-g--white .button-g__text {
        font-size:12px;
    }
    .center--zigzag .zigzap-g--white {
        background-color:#f2f2f2;
    }
    .center--zigzag .zigzap-g__item {
        height:658px;
    }
    .center--zigzag .title-g__icon {
        width:64px;
        margin-bottom:16px;
    }
    .center--zigzag .zigzap-g .zigzap-g__info {
        padding-right:80px;
    }
    .item-descarga__title {
        min-height:56px;
    }
    .item-descarga__title > h3 {
        font-size:15px;
    }
    .item-descarga__list {
        padding:15px 30px 10px 30px;
    }
    .item-descarga__listTitle {
        padding-bottom:15px;
    }
    .productd-tabContentItem h3.title-g__fz--17 {
        font-size:13.5px;
    }
    .item-descarga__listTitle > h4 {
        font-size:15px;
    }
    .productd-tabContentItem .item-descarga__listTitle > h4 {
        font-size:13.5px;
    }
    .item-descarga__listInTitle > h5 {
        font-size:15px;
    }
    .item-descarga__listInContent p {
        font-size:15px;
    }
    .item-descarga__listInContent span {
        font-size:15px;
    }
    .item-descarga__listInContent a {
        font-size:15px;
    }
    .banner-g--averia .banner-g__image {
        height:620px;
    }
    .login-submit .button {
        border-radius:4px !important;
    }
    .portalgp-blockInfoText {
        font-size:16px;
    }
    .olvidaste--js, .olvidastecursos--js, .olvidasteplataforma--js {
        font-size:15px;
    }
    .portalgp-blockLogin input[type="text"], .portalgp-blockLogin input[type="password"] {
        height:34px !important;
        line-height:34px !important;
        font-size:16px !important;
    }
    .portalgp-blockLogin, .portalgp-blockInfo {
        height:500px;
    }
    /* Blog */
    .input-g__input {
        height:30px;
        line-height:30px;
        font-size:14px !important;
    }
    .input-g--select .input-g__input {
        color:#737373 !important;
    }
    .input-g--select .input-g__ico {
        top:13px;
    }
    .item-g4__title h3 {
        font-size:26px;
    }
    .stylesAll-content {
        font-size:16px;
    }
    .input-g__input {
        font-size:14px !important;
    }
    .portalgp {
        background:linear-gradient(to right, #e9590c 60%, #d43200 100%);
    }
    .portalgp-block1Menu > ul > li.current_page_item > a {
        font-size:20px;
    }
    .portalgp-rplataformaItemImage {
        width:110px;
    }
    .portalgp-block1Menu > ul > li > a {
        font-size:16px;
    }
    .portalgp-rplataformaItem {
        padding:80px 55px !important;
    }
    .portalgp-rplataformaItemText > h3 {
        font-size:16px;
    }
    .portalgp-rplataformaItemText > section {
        font-size:15px;
    }
    .portalgp-blockLoginTitle > h3 {
        font-size:26px;
    }
    .portalgp-blockInfoTitle > h3 {
        font-size:26px;
    }
    .portalgp-rProductosTitle > h3 {
        font-size:16px;
    }
    .portalgp-rProductosNum {
        font-size:16px;
    }
    /* Modals */
    .popUp-gcontacto .popUp-g__popUpTitle > h3 {
        font-size:26px;
    }
    .input-g__title {
        font-size:13px;
    }
    .input-g__input {
        font-size:16px !important;
    }
    .input-g__checkboxWP label > span {
        font-size:13px;
    }
    .button-g--gradient .button-g__text {
        font-size:13px;
    }
    .button-g__text {
        height:40px;
        line-height:40px;
        padding:0 16px !important;
        padding:0 28px !important;
        border-radius:4px !important;
    }
    .popUp-g__close {
        font-size:24px;
    }
    .productdetail-info {
        margin-top:100px;
    }
    .popUp-gMessageFault .popUp-g__popUpContent {
        font-size:17px;
    }
    .popUp-gMessageFault .popUp-g__popUpTitle > h3 {
        font-size:18px;
    }
    .popup-carrito__message {
        padding:20px 40px;
        border-radius:8px;
    }
    .popup-carrito__messageBtn {
        font-size:13px;
        letter-spacing:1px;
    }
    .popup-carrito__messageText p {
        font-size:13px;
        letter-spacing:1px;
    }
    .popup-carrito__messageText {
        border-right:1px solid #fff;
    }
    #toast .popup-carrito__messageText {
        border-right:none;
        padding-right:0;
    }
    .title-g__fz--15.fontBold.colorOrange.g--uppercase {
        padding-bottom:30px;
    }
    .product-compromisos .block-g__image {
        width:40px;
        height:40px;
    }
}
/* HDPI */
@media (min-width:1601px) and (max-width:2600px) {
    .slider-principal__bg {
        height:720px;
    }
    .table__g thead th {
        font-size:15px;
        color:#000;
    }
    .table__g tbody td p {
        font-size:15px;
    }
    .miga-g > ul > li > a {
        letter-spacing:1.35px
    }
    .productpage__container {
        height:720px;
    }
    .productpage__bg {
        height:720px;
    }
    html {
        font-size:20px;
    }
    .slider-principal, .slider-principal__container, .banner-g--catproducto .banner-g__image {
        height:720px;
    }
    .banner-g--nosotros .banner-g__image {
        height:720px;
    }
    .banner-g--soporte .banner-g__image {
        height:720px;
    }
    .banner-g--distribuidores .banner-g__image {
        height:720px;
    }
    .slider-principal__infoTitle h1 {
        font-size:64px;
    }
    .titleText-g__fz--16 {
        font-size:19px;
    }
    .categories-g__name > h3 {
        font-size:21px;
    }
    .categories-g__name {
        width:45%;
    }
    .block-text--nosotros .title-g__fz--52.fontLight.colorWhite.g--uppercase {
        font-size:64px;
    }
    .block-g__title2 > h3 {
        font-size:22px;
    }
    .block-g__textIn {
        font-size:16px;
    }
    .item-g__product3Title > h3 {
        font-size: 17px;
    }
    .item-g__product3Cat > h4 {
        font-size: 17px;
    }
    .title-g__fz--24 {
        font-size:28px;
    }
    .title-g__fz--26 {
        font-size:28px;
    }
    .title-g__fz--36 {
        font-size:44px;
    }
    .title-g__fz--52.fontLight.colorWhite.g--uppercase {
        font-size:64px;
    }
    .title-g__fz--38 {
        font-size:48px;
    }
    .title-g__fz--52 {
        font-size:65px;
    }
    .title-g__fz--56 {
        font-size:72px;
    }
    .title-g__fz--30 {
        font-size:38px;
    }
    .title-g__fz--48 {
        font-size:62px;
    }
    .title-g__icon {
        max-width:80px;
    }
    .home--catalogo {
        background:none;
    }
    .item-g__familiaText {
        font-size:17px;
    }
    .banner-g--catalog {
        max-width:100%;
        padding:0;
    }
    .productd-tabacordion__container.wrapper-container {
        max-width:100%;
        padding:0 150px;
    }
    .banner-g--catalog .wrapper-container {
        max-width:100%;
    }
    .home--catalogo.wrapper-paddingBottom {
        padding-bottom:0 !important;
    }
    .banner-g--catalog .banner-g__info {
        margin-right:16%;
    }
    .productdetail-miga {
        padding-bottom:0;
    }
    .portalgp-blockInfoButton .button-g__text {
        height:50px;
        line-height:50px;
    }
    .portalgp-blockInfoButton .button-g__text {
        font-weight:400;
        font-size:15px !important;
    }
    .woocommerce-button, .woocommerce-Button, .button {
        height:50px !important;
        line-height:50px;
        font-size:14px;
    }
    .productdetail-info {
        margin-top:120px;
    }
    .productdetail-image img {
        width:90%;
    }
    .productdetail {
        padding-bottom:100px;
    }
    .popUp-gMessageFault .popUp-g__popUp {
        height:360px;
    }
    .banner-g--distribuidores .banner-g__info.right {
        right:4%;
    }
    #compromisos .block-g__item .block-g__imageText img {
        height:50px;
        width:50px;
    }
    .attachment-post-thumbnail.size-post-thumbnail.wp-post-image {
        width:100%;
    }
    .productd-tabCuentacon .block-g__item .block-g__imageText img {
        height:50px;
        width:50px;
    }
    .portalgp-blockLogin, .portalgp-blockInfo {
        height:580px;
        padding:65px 50px;
    }
    .portalgp-blockInfoText {
        font-size:20px;
    }
    .productd-tabIdealItemTitle > h3 {
        font-size:11px;
    }
    span.tooltip a {
        font-size:15px;
    }
    span.tooltip {
        font-size:16px;
    }
}
@media (min-width:1025px) {
    .mobile-only {
        display:none;
    }
    .hide-mobile {
        display:block;
    }
}
